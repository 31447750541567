import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import moment from 'moment';
import KtDatePickerField from "../../../components/KtDatePickerField";
import { Input, Select, DatePickerField } from "../../../../_metronic/_partials/controls";
import { Typeahead } from "react-bootstrap-typeahead";


const BookingModalForm = ({
    handleSave,
    bookingToSet,
    actionsLoading,
    onHide,
    calendarBookings,
    serviceProviders,
    searchServiceProviderByMobile
}) => {
    const { id, start, end, customer } = bookingToSet;

    const initialValues = { ...customer, id, start, end }

    const setCustomer = (customer, setFieldValue) => {
        const { name, dob, gender } = customer;
        setFieldValue('dob', dob ? moment(dob, 'DD-MM-YYYY').toDate() : moment().toDate());
        setFieldValue('gender', gender);
        setFieldValue('name', name);
    }

    initialValues.dob = moment(initialValues.dob, 'DD-MM-YYYY').toDate();

    const searchByMobile = (mobile, setFieldValue) => {
        if (mobile.length === 10) {
            searchServiceProviderByMobile(mobile);
        }
    }

    const checkIfAlreadyMeeting = (start, end) => {
        let result = false;
        result = calendarBookings.some((event) => {
            const res =
                (event.start < start && start < event.end) ||
                (end > event.start && end < event.end) ||
                (start === event.start && end === event.end);
            if (id) {
                return res && event.id !== id;
            }
            return res;
        });

        return result;
    };

    const handleDateChange = (name, value, setInputValue) => {
        if (name === "start") {
            setInputValue('end', moment(value).add(1, "hour").toDate());
        }
        // if (name === "customer.dob") {
        //     const dob = moment(value).toDate();
        //     customer.dob = dob;
        //     setInputValue('customer.dob', dob);
        // }
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                // validationSchema={CustomerEditSchema}
                validate={(values) => {
                    const errors = {};

                    const { start, end, name, gender, mobile, dob } = values;

                    if (!start) {
                        errors.start = "From Date is required";
                    }

                    if (!end) {
                        errors.end = "To Date is required";
                    }

                    if (!name) {
                        errors.name = "Name is required";
                    }

                    if (!gender) {
                        errors.gender = "Gender is required";
                    }

                    if (!mobile) {
                        errors.mobile = "Mobile is required";
                    }

                    if (!dob) {
                        errors.dob = "Date of birth is required";
                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    const { start, end, name, gender, mobile, dob } = values;
                    const bookingData = {
                        customer: {
                            // ...bookingToSet.customer,
                            name, gender, mobile, dob
                        },
                        start: "",
                        end: "",
                    };

                    bookingData.customer.dob = moment(dob).format("DD-MM-YYYY HH:mm:ss");
                    bookingData.start = moment(start).format("YYYY-MM-DD HH:mm:ss");
                    bookingData.end = moment(end).format("YYYY-MM-DD HH:mm:ss");

                    if (
                        checkIfAlreadyMeeting(bookingData.start, bookingData.end)
                    ) {
                        setStatus("There is already a meeting Schedule at this time!!");
                    }
                    if (
                        start.getHours() < 7 ||
                        start.getHours() > 22 ||
                        end.getHours() < 7 ||
                        end.getHours() > 22
                    ) {
                        setStatus("Invalid Hours for Meeting!");
                    }
                    if (start < new Date() || end < new Date()) {
                        setStatus("Booking Meetings for Past Dates are not Allowed!");
                    }
                    if (initialValues.id) {
                        bookingData.id = initialValues.id;
                    }
                    handleSave(bookingData, { setStatus, setSubmitting });
                }}
            >
                {({ values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                }) => (
                        <>
                            <Modal.Body className="overlay overlay-block">
                                {actionsLoading && (
                                    <div className="overlay-layer bg-transparent">
                                        <div className="spinner spinner-lg spinner-success" />
                                    </div>
                                )}
                                {status ? (
                                    <div
                                        role="alert"
                                        className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                                    >
                                        <div className="alert-text font-weight-bold">{status}</div>
                                    </div>
                                ) : null}
                                < Form className="form form-label-right" noValidate={true}
                                    autoComplete="off">

                                    <div className="form-group row">

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Field
                                                    name="mobile"
                                                    component={Input}
                                                    placeholder="Search By Mobile"
                                                    label="Search By Mobile"
                                                    withFeedbackLabel={false}
                                                    maxLength="10"
                                                    onBlur={e => {
                                                        // call the built-in handleBur
                                                        handleBlur(e)
                                                        // and do something about e
                                                        let mobileValue = e.currentTarget.value
                                                        searchByMobile(mobileValue, setFieldValue)
                                                    }}

                                                />
                                            </div>
                                        </div>

                                        {
                                            serviceProviders && serviceProviders.length > 0 ?
                                                (
                                                    <>
                                                        {/* <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <Select name="name" label="Name" withFeedbackLabel={false}>
                                                                    <option value="">Select</option>
                                                                   
                                                                    {serviceProviders.map((serviceProvider) => (
                                                                        <option key={serviceProvider.id} value={serviceProvider.name}>
                                                                            {serviceProvider.name}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <Field
                                                                    name="name"
                                                                    component={Input}
                                                                    placeholder="Name"
                                                                    label="Name"
                                                                    withFeedbackLabel={false}
                                                                />
                                                            </div>
                                                        </div>
                                                        */}

                                                        <div className="col-lg-12">
                                                            <label>User</label>
                                                            <div className="form-group">
                                                                <Typeahead
                                                                    id="labelkey-example"
                                                                    labelKey={(option) => `${option.name}`}
                                                                    options={serviceProviders}
                                                                    placeholder="Select User"
                                                                    // defaultInputValue={customer.name}
                                                                    defaultSelected={[serviceProviders[0]]}
                                                                    onInputChange={(val) => {
                                                                        // Handle selections...
                                                                        const cust = {
                                                                            name: val,
                                                                            dob: '',
                                                                            gender: ''
                                                                        }
                                                                        setCustomer(cust, setFieldValue);
                                                                    }}
                                                                    onChange={(selected) => {
                                                                        // Handle selections...
                                                                        if (selected.length > 0) {
                                                                            setCustomer(selected[0], setFieldValue);
                                                                        }
                                                                    }}
                                                                // renderInput={(inputProps) => (
                                                                //     <FormControl {...inputProps} />
                                                                // )}

                                                                />
                                                            </div>
                                                        </div>


                                                    </>
                                                ) :
                                                (
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <Field
                                                                name="name"
                                                                component={Input}
                                                                placeholder="Name"
                                                                label="Name"
                                                                withFeedbackLabel={false}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                        }



                                        <div className="col-lg-12">
                                            <label>Date of Birth</label>
                                            <div className="form-group customDatePickerWidth">
                                                <DatePickerField
                                                    name="dob"
                                                    label="Date of Birth"
                                                    // selected={dob}
                                                    // onChange={(date) => handleDobChange("dob", date)}
                                                    maxDate={new Date()}
                                                    minDate={moment(new Date()).subtract(100, "years").toDate()}
                                                    dateFormat="MMMM d, yyyy"
                                                />

                                                {/* <DatePicker
                                                    selected={dob}
                                                    // onChange={(date) => handleSearchChange("dob", date)}
                                                    maxDate={new Date()}
                                                    minDate={moment(new Date()).subtract(100, "years").toDate()}
                                                    dateFormat="MMMM d, yyyy"
                                                /> */}
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Select name="gender" label="Gender" withFeedbackLabel={false}>
                                                    <option value="">Select</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Others">Others</option>
                                                </Select>
                                            </div>
                                        </div>

                                        {/* start Date */}
                                        <div className="col-lg-12">
                                            <label>From</label>
                                            <div className="form-group customDatePickerWidth">
                                                <KtDatePickerField
                                                    name="start"
                                                    label="To"
                                                    selectedDate={start}
                                                    handleChange={handleDateChange}
                                                    setInputValue={setFieldValue}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>To</label>
                                            <div className="form-group customDatePickerWidth">
                                                <KtDatePickerField
                                                    name="end"
                                                    label="To"
                                                    selectedDate={end}
                                                    handleChange={handleDateChange}
                                                    setInputValue={setFieldValue}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="button"
                                    onClick={() => onHide(false)}
                                    className="btn btn-light btn-elevate"
                                >
                                    Cancel
                            </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-elevate"
                                >
                                    Save
                                    {isSubmitting && (
                                        <span className="ml-3 spinner spinner-white"></span>
                                    )}
                                </button>
                            </Modal.Footer>
                        </>
                    )}
            </Formik>
        </>
    );
}


export default React.memo(BookingModalForm);