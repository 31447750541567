import { createSlice } from "@reduxjs/toolkit";

const initialCouponState = {
  loading: false,
  success: "",
  error: "",
  coupon: null,
  fetchCoupon: null,
  fetchApp: null,
  getAllCoupons: null,
  AvailedCoupon: null,
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState: initialCouponState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state.loading = false;
    },
    catchSuccessMessage: (state, action) => {
      state.success = `${action.payload.success}`;
      state.loading = false;
    },
    startCall: (state) => {
      state.error = null;
      state.loading = true;
    },
    // coupon
    couponData: (state, action) => {
      console.log("action payload", action.payload);
      state.loading = false;
      state.coupon = action.payload.coupon;
      state.error = null;
    },

    fetchCouponData: (state, action) => {
      console.log("action payload", action.payload);
      state.loading = false;
      state.fetchCoupon = action.payload.couponResponse;
      state.error = null;
    },

    fetchAppDetails: (state, action) => {
      console.log("action payload", action.payload);
      state.loading = false;
      state.fetchApp = action.payload.couponResponse;
      state.error = null;
    },
    totalCoupons: (state, action) => {
      console.log("total payload", action.payload);
      state.loading = false;
      state.getAllCoupons = action.payload.couponResponse;
      state.error = null;
    },
    CouponAvailed: (state, action) => {
      console.log("total payload", action.payload);
      state.loading = false;
      state.AvailedCoupon = action.payload.couponResponse;
      state.error = null;
    },
  },
});
