import axios from "axios";
import __env from "../../../../env";
export function CreateRetailer(formData) {
  
    return axios.post(`${__env.dataApi}/auth/create-retailer?appId=${""}`,formData);
}

export function getALLRetailers(token) {
  
    return axios.get(`${__env.dataApi}/adminDashboard/getRetailers`,{headers:{ Authorization: `Bearer ${token||''}`}});
}