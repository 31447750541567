import { createSlice } from "@reduxjs/toolkit";

const initialBookingState = {
    loading: false,
    success: '',
    error: '',
    booking:null,
    allBookings:null,
    fetchBooking:null
};

export const bookingSlice = createSlice({
    name: "booking",
    initialState: initialBookingState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.payload.error}`;
            state.loading = false;
        },
        catchSuccessMessage: (state, action) => {
            state.success = `${action.payload.success}`;
            state.loading = false;
        },
        startCall: (state) => {
            state.error = null;
            state.loading = true;
        },
        // coupon
        bookingData: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.booking = action.payload.booking;
            state.error = null;
        },
        bookingFetched: (state, action) => {
           
            state.loading = false;
            state.fetchBooking = action.payload.bookingResponse;
            state.error = null;
        },
        totalBookings: (state, action) => {
            console.log("total payload", action.payload)
            state.loading = false;
            state.allBookings = action.payload.bookingResponse;
            state.error = null;
        },
        
        }
});
