import { createSlice } from "@reduxjs/toolkit";

const initialCalendarsState = {
    loading: false,
    calendarBookings: [],
    success: '',
    error: '',
    clients: [],
    serviceProviders: [],
    selectedBooking: null
};

export const calendarsSlice = createSlice({
    name: "calendars",
    initialState: initialCalendarsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.payload.error}`;
            state.loading = false;
        },
        catchSuccessMessage: (state, action) => {
            state.success = `${action.payload.success}`;
            state.loading = false;
        },
        startCall: (state) => {
            state.error = null;
            state.loading = true;
        },
        // fetch bookings
        bookingsFetched: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.calendarBookings = action.payload.calendarBookings;
            state.error = null;
        },
        // fetch booking
        bookingFetched: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.selectedBooking = action.payload.selectedBooking;
            state.error = null;
        },
        // fetch data
        serviceProvidersFetched: (state, action) => {
            state.loading = false;
            state.serviceProviders = action.payload.serviceProviders;
            state.error = null;
        },
        // create Meeting
        bookingCreated: (state, action) => {
            state.loading = false;
            state.success = action.payload.success;
            state.calendarBookings = [...state.calendarBookings, action.payload.booking];
            state.error = null;
        },
        // update Meeting
        bookingUpdated: (state, action) => {
            state.loading = false;
            state.success = action.payload.success;
            state.error = null;
        },
    }
});
