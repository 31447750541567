import React from 'react';
import { invalidMeeting } from '../utils';

const InvalidMeetingPage = (props) => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const errorType = params ? params.get('type') : '';
    console.log("errorType", errorType);
    return (
        <div className="d-flex flex-column flex-root">
            {/*begin::Error*/}
            <div className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center" style={{ backgroundImage: 'url(/media/error/bg6.jpg)' }}>
                {/*begin::Content*/}
                <div className="d-flex flex-column flex-row-fluid text-center">
                    <h1 className="error-title font-weight-boldest text-white mb-12" style={{ marginTop: '12rem' }}>
                        {errorType ? invalidMeeting[errorType] : 'Oops...'}
                    </h1>
                    {/* <p className="display-4 font-weight-bold text-white">
                Looks like something went wrong.<br />
                We're working on it
              </p> */}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Error*/}
        </div>
    );
}

export default InvalidMeetingPage;