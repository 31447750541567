import axios from "axios";

export function fetchBookings() {
    return axios.get(`bookings/history`);
}

export function fetchBookingById(bookingId) {
    return axios.get(`bookings/${bookingId}`);
}

export function fetchServiceProviderByMobile(mobile) {
    return axios.get(`/customers/mobile/${mobile}`);
}

export function createBooking(bookingData) {
    return axios.post(`/bookings`, bookingData);
}

export function editBooking(bookingId, bookingData) {
    return axios.put(`/bookings/${bookingId}`, bookingData);
}

export function createUserBooking(userId, bookingData) {
    return axios.post(`/users/${userId}/bookings`, bookingData);
}

export function createCustomer(customerData) {
    return axios.post(`/customers`, customerData);
}

export function cancelBooking(bookingId) {
    return axios.put(`/bookings/${bookingId}/cancel`);
}

export function saveBookingNotes(bookingId, notes) {
    return axios.put(`/bookings/${bookingId}/notes`, notes);
  }

export function goToUrl(url) {
    return axios.get(`${url}`);
}