import * as requestFromServer from "./appCrud";
import { appSlice } from "./appSlice";
import { getErrorMessage } from "../../../utils";

const { actions } = appSlice;

export const App = (token,category,page,data) => (dispatch) => {
    dispatch(actions.startCall());
    return requestFromServer
        .createApp(token,category,page,data)
        .then((response) => {
            const appResponse = response.data;
            dispatch(actions.appData({ appResponse }));
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            dispatch(actions.catchError({ error: errorMessage }));
        });
};

export const FetchAllRetailerApps = (id) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
      .getAllApps(id)
      .then((response) => {
          const appResponse = response.data;
          dispatch(actions.appsFetched({ appResponse }));
          console.log("response",response)
      })
      .catch((error) => {
          const errorMessage = getErrorMessage(error);
          dispatch(actions.catchError({ error: errorMessage }));
      });
};


export const FetchAllApps = (token) => (dispatch) => {
    dispatch(actions.startCall());
    return requestFromServer
        .fetchAppsList(token)
        .then((response) => {
            const appResponse = response.data;
            dispatch(actions.appsList({ appResponse }));
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            dispatch(actions.catchError({ error: errorMessage }));
        });
  };
  

  export const FetchAllCategories = (token) => (dispatch) => {
    dispatch(actions.startCall());
    return requestFromServer
        .GetAllCategories(token)
        .then((response) => {
            const appResponse = response.data;
            dispatch(actions.appCategory({ appResponse }));
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            dispatch(actions.catchError({ error: errorMessage }));
        });
  };
  

  export const FetchAllPages = (token,category) => (dispatch) => {
    dispatch(actions.startCall());
    return requestFromServer
        .GetAllPages(token,category)
        .then((response) => {
            const appResponse = response.data;
            dispatch(actions.fetchLandingPage({ appResponse }));
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            dispatch(actions.catchError({ error: errorMessage }));
        });
  };
  

  export const AssigningApps = (token,appId,id) => (dispatch) => {
     
    dispatch(actions.startCall());
    return requestFromServer
        .AppsAssigning(token,appId,id)
        .then((response) => {
            const appResponse = response.data;
            dispatch(actions.assigingAppsToRetailer({ appResponse }));
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            dispatch(actions.catchError({ error: errorMessage }));
        });
  };
  


