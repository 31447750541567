import axios from "axios";
import __env from "../../../../env";

export const ORGANIZATIONS_URL = "organizations";

// CREATE =>  POST: add a new organization to the server
export function createOrganization(organization) {
  return axios.post(ORGANIZATIONS_URL, organization);
}

// READ
export function getAllOrganizations() {
  return axios.get(ORGANIZATIONS_URL);
}

export function getOrganizationById(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
// export function findOrganizations(queryParams) {
//   const { filter, pageSize, sortField, sortOrder } = queryParams;
//   const pageNumber = +queryParams.pageNumber - 1;
//   return axios.post(`${ORGANIZATIONS_URL}/find`, filter, {
//     params: {
//       pageNumber, pageSize, sortField, sortOrder
//     }
//   });
// }

export function findOrganizations() {
  return axios.get(`${ORGANIZATIONS_URL}`);
}
// UPDATE => PUT: update the organization on the server
export function updateOrganization(id, organization) {
  return axios.put(`${ORGANIZATIONS_URL}/${id}`, organization);
}

// UPDATE Status
export function updateStatusForOrganizations(ids, status) {
  return axios.post(`${ORGANIZATIONS_URL}/updateStatusForOrganizations`, {
    ids,
    status,
  });
}

// DELETE => delete the organization from the server
export function deleteOrganization(organizationId) {
  return axios.delete(`${ORGANIZATIONS_URL}/${organizationId}`);
}

// DELETE Organizations by ids
export function deleteOrganizations(ids) {
  return axios.post(`${ORGANIZATIONS_URL}/deleteOrganizations`, { ids });
}

export function findOrganisationByDomain(domain) {
  return axios.get(`${__env.dataApi}/${ORGANIZATIONS_URL}/domain`, {
    params: {
      domain: domain,
    },
  });
}

export function findOrganisationInfo() {
  return axios.get(`${__env.dataApi}/info`);
}
