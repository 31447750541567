import React, { useEffect, useState } from "react";

import { Formik, Field } from "formik";
import { CircularProgress, Box, Typography, Card } from "@material-ui/core";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Input } from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Booking, fetchBookingData } from "../redux/bookingAction";

toast.configure();

const BookingForm = ({ ...props }) => {
  const { intl } = props;
  const { appid,couponcode } = useParams();
  

  console.log("appid", appid);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const OpenTime = props.getBooking ? props.getBooking.app.openTime : "";
  const CloseTime = props.getBooking ? props.getBooking.app.closeTime : "";

  const openTime = new Date(OpenTime).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    timeZone: "Asia/Kolkata",
  });
  const closeTime = new Date(CloseTime).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    timeZone: "Asia/Kolkata",
  });

  const date = new Date();
  const formatedDate = (value) => {
    let date = new Date(value);
    return (
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2)
    );
  };
  const handleBooking = (values) => {
    const startDateStr = formatedDate(values.startTime);
    const endDateStr = formatedDate(values.endTime);

    if (startDateStr > endDateStr) {
      alert("End date need to be bigger then start date");
    }
    const formData = {
      ...values,
      startTime: startDateStr,
      endTime: endDateStr,
      appId: appid,
      couponCode:couponcode,
    };
    if (formData) {
      setLoading(true);
      props
        .Booking(formData)
        .then((response) => {
          toast.success("Booking created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
          setLoading(false);
        });
    }
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const numberRegExp = /^\d+$/;
  useEffect(() => {
    props.fetchBookingData(appid);
    console.log("dattata", props);
  }, []);

  return (
    <div>
      {!success ? (
        <div className="row">
          <div className="col-lg-6 m-auto">
            <div className="text-center mt-2 mb-lg-2">
              <div className="card-header">
                <img
                  src={
                    props.getBooking &&
                    props.getBooking.user.retailer.retailerImage
                  }
                  className="h-50 align-self-center"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
                <div
                  style={{
                    float: "right",
                    position: "absolute",
                    zIndex: "1",
                    width: "87%",
                  }}
                >
                  <div
                    style={{
                      width: "100px",
                      marginTop: "-55%",
                      border: "0px solid black",
                      float: "left",
                    }}
                  >
                    <img
                      src={
                        props.getBooking && props.getBooking.user.retailer.logo
                      }
                      className="h-50 align-self-center"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100px",
                      marginTop: "-48%",
                      border: "2px solid black",
                      float: "right",
                    }}
                  >
                    <img
                      src={
                        props.getBooking && props.getBooking.user.retailer.logo
                      }
                      className="h-50 align-self-center"
                      style={{
                        display: "none",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {props.getBooking ? (
              <div className="text-center mt-2 mb-lg-2">
                <div className="card-header">
                  <h2 className="text-dark font-weight-bold">
                    {props.getBooking.app.tagLine}
                  </h2>
                  <p className="text-dark font-weight-bold">
                    {props.getBooking.app.description}
                  </p>
                </div>
              </div>
            ) : (
              <div className="text-center mt-2 mb-lg-2">
                <div className="card-header">
                  <h2 className="text-muted font-weight-bold">
                    No Tagline Found
                  </h2>
                  <p className="text-muted font-weight-bold">No Description</p>
                </div>
              </div>
            )}

            <Formik
              // enableReinitialize={true}
              initialValues={{
                name: "",
                email: "",
                mobile: "",
                startTime: "",
                endTime: "",
                numberOfPersons: "",
                specialMessage: "",
              }}
              /*  validationSchema={Yup.object().shape({
                                      name: Yup.string().required('name is required'),
                                      email: Yup.string()
                                          .email("Invalid email address format")
                                          .required("Email is required"),
                                      mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
                                      startTime: Yup.date().required(),
                                      endTime: Yup.date().min(
                                          Yup.ref('startTime'),
                                          "end date can't be before start date"
                                      ),
                                      numberOfPersons: Yup.number().required('Person is required'),

                                      specialMessage: Yup.string().required('Special Message is required'),
                                  })}*/

              validate={(values) => {
                const errors = {};

                if (!values.name) {
                  errors.name = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                }

                if (!values.email) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD",
                  });
                }

                if (!values.mobile) {
                  errors.mobile = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                } else if (!phoneRegExp.test(values.mobile)) {
                  errors.mobile = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD",
                  });
                }

                if (!values.startTime) {
                  errors.startTime = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                }

                if (!values.endTime) {
                  errors.endTime = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                }

                if (!values.numberOfPersons) {
                  errors.numberOfPersons = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                } else if (!numberRegExp.test(values.numberOfPersons)) {
                  errors.numberOfPersons = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD",
                  });
                }
                if (!values.specialMessage) {
                  errors.specialMessage = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                }
                return errors;
              }}
              onSubmit={handleBooking}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  className="mt-4 mb-4"
                  noValidate={true}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <Field
                            name="name"
                            component={Input}
                            placeholder="Name"
                            label="Name"
                            onChange={handleChange}
                            value={values.name}
                            withFeedbackLabel={false}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <Field
                            name="email"
                            component={Input}
                            placeholder="Email"
                            label="Email"
                            value={values.email}
                            onChange={handleChange}
                            withFeedbackLabel={false}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <Field
                            name="mobile"
                            component={Input}
                            placeholder="Mobile Number"
                            label="Mobile Number"
                            onChange={handleChange}
                            value={values.mobile}
                            withFeedbackLabel={false}
                            maxLength="10"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group customDatePickerWidth">
                          <Field
                            name="startTime"
                            label="StartTime"
                            component={Input}
                            placeholder="StartDate"
                            onChange={handleChange}
                            value={values.startTime}
                            type="datetime-local"
                            withFeedbackLabel={false}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group customDatePickerWidth">
                          <Field
                            name="endTime"
                            label="EndTime"
                            component={Input}
                            placeholder="EndDate"
                            onChange={handleChange}
                            value={values.endTime}
                            type="datetime-local"
                            withFeedbackLabel={false}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <Field
                            name="numberOfPersons"
                            component={Input}
                            placeholder="No. of Persons"
                            label="Persons"
                            onChange={handleChange}
                            value={values.persons}
                            withFeedbackLabel={false}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <Field
                            name="specialMessage"
                            component={Input}
                            placeholder="Special Request"
                            label="Special Request"
                            onChange={handleChange}
                            value={values.specialMessage}
                            withFeedbackLabel={false}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary btn-elevate"
                            style={{ width: "100%" }}
                            endIcon={
                              loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )
                            }
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                    {props.getBooking ? (
                      <div className="card-footer">
                        <p style={{ fontSize: "12px" }}>
                          <span style={{ color: "red" }}>*</span> RetailerShop
                          will be open from {openTime} to {closeTime}
                        </p>
                      </div>
                    ) : (
                      <div className="card-footer">
                        <p style={{ fontSize: "12px" }}>
                          <span style={{ color: "red" }}>*</span> RetailerShop
                          will be open from 00:00 A.M to 00:00 P.M
                        </p>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="text-center mt-2 mb-lg-2" style="width: 18rem;">
          <div className="card-header">
            <h2 className="text-dark font-weight-bold">
              Thank you for Booking
            </h2>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.bookings.error,
  success: state.bookings.success,
  loading: state.bookings.loading,
  booking: state.bookings.booking,
  getBooking: state.bookings.fetchBooking,
});

const mapActionsToProps = {
  Booking,
  fetchBookingData,
};
//export default connect(mapStateToProps, mapActionsToProps)(BookingForm);
export default injectIntl(
  connect(mapStateToProps, mapActionsToProps)(BookingForm)
);
