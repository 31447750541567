import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { UsersPage } from "./pages/Users/UsersPage";
import { OrganizationsPage } from "./pages/Organizations/OrganizationsPage";
import ApplicationsPage from "./pages/Applications/ApplicationsPage";
import RetailerDashboardPage from "./pages/RetailerDashboard/RetailerDashboardPage";
import RetailerApplicationDetail from "./pages/Applications/RetailerApplications/RetailerApplicationDetail";
import AppListPage from "./pages/AppList/AppListPage";
import { CreateUserPage } from "./pages/Applications/CreateUserPage";
import { CreateAppPage } from "./components/AdminDashboard/DasboardAppsPage/CreateAppPage";
import AdminApps from "./pages/AppList/AdminApps";
import BookingsPage from "./pages/Booking/BookingsPage";
import CouponsPage from "./pages/Coupon/CouponsPage";
import CreateCouponPage from "./pages/Coupon/CreateCouponPage";
import { useSelector, shallowEqual } from "react-redux";
import { rolesUtil } from "./utils";

//const MBookingsPage = lazy(() =>
//import("./pages/Bookings/BookingsPage")
//);
const MExpertsPage = lazy(() => import("./pages/Experts/ExpertsPage"));
const MHolidaysPage = lazy(() => import("./pages/Holidays/HolidaysPage"));
const MProfilePage = lazy(() => import("./modules/Profile/ProfilePage"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  // console.log("HOSTNAME",window.location.hostname)
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  console.log(user, rolesUtil(user.roles));

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */
        user.roles && user.roles.length > 0 && (
          <Redirect exact from="/" to={rolesUtil(user.roles)} />
        )}
        <Route path="/experts" component={MExpertsPage} />
        {/*<Route path="/bookings" component={MBookingsPage} />*/}
        <Route path="/retailers" component={ApplicationsPage} />
        <Route path="/apps" component={AdminApps} />
        <Route path="/applist/:id" component={AppListPage} />
        <Route path="/create-app" component={CreateAppPage} />
        <Route path="/create-coupon/:appid" component={CreateCouponPage} />
        <Route path="/applications" component={RetailerDashboardPage} />
        <Route
          path="/retailer-application/:id"
          component={RetailerApplicationDetail}
        />
        <Route path="/holidays" component={MHolidaysPage} />
        <Route path="/create-user" component={CreateUserPage} />
        <Route path="/get-bookings" component={BookingsPage} />
        <Route path="/allcoupons" component={CouponsPage} />
        {/* <ContentRoute path="/experts/new" exact component={ExpertNewPage} />
                <ContentRoute path="/experts/list" exact component={ExpertsListPage} />
                <ContentRoute path="/experts/:id/booking" exact component={ExpertBookingNew} />*/}
        <ContentRoute path="/users" component={UsersPage} />
        <ContentRoute path="/organizations" component={OrganizationsPage} />
        <ContentRoute path="/profile" component={MProfilePage} />
        {/* <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/e-commerce" component={ECommercePage}/> */}
      </Switch>
    </Suspense>
  );
}
