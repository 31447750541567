// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input
} from "../../../../_metronic/_partials/controls";

const phoneRegExp = /^[6-9]\d{9}$/;//!/^(6|7|8|9)\\d{9}$/;
// Validation schema
const OrganizationEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("name is required"),

  primaryContactName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("name is required"),


  primaryEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),

  primaryMobile: Yup.string().matches(phoneRegExp, 'Mobile is not valid')
    .required("mobile is required"),

  // dateOfBbirth: Yup.mixed()
  //   .nullable(false)
  //   .required("Date of Birth is required"),
  // ipAddress: Yup.string().required("IP Address is required"),
  // name: "",
  // primaryContactName: "",
  // primaryEmail: "",
  // primaryMobile: "",
});

export function OrganizationEditForm({
  saveOrganization,
  organization,
  actionsLoading,
  onHide,
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={organization}
        validationSchema={OrganizationEditSchema}
        onSubmit={(values) => {
          saveOrganization(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="row">
                  <div className="col-lg-12">
                    {/* Organization Name */}
                    <div className="form-group">
                      {/* First Name */}
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Organization Name"
                        label="Organization Name"
                        withFeedbackLabel={false}
                      />
                    </div>

                    {/* Primary Name */}
                    <div className="form-group">
                      <Field
                        name="primaryContactName"
                        component={Input}
                        placeholder="Primary Contact Name"
                        label="Primary Contact Name"
                        withFeedbackLabel={false}
                      />
                    </div>

                    {/*Primary Email */}
                    <div className="form-group">
                      <Field
                        type="email"
                        name="primaryEmail"
                        component={Input}
                        placeholder="Primary Email"
                        label="Primary Email"
                        withFeedbackLabel={false}
                      />
                    </div>

                    {/* Organization Mobile */}
                    <div className="form-group">
                      <Field
                        name="primaryMobile"
                        component={Input}
                        placeholder="Primary Mobile"
                        label="Primary Mobile"
                        maxLength="10"
                        withFeedbackLabel={false}
                      />
                    </div>
                  </div>

                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
