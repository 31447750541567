// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useSelector } from "react-redux";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../OrganizationsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useOrganizationsUIContext } from "../OrganizationsUIContext";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';

export function OrganizationsTable() {
  // Organizations UI Context
  const organizationsUIContext = useOrganizationsUIContext();
  const organizationsUIProps = useMemo(() => {
    return {
      ids: organizationsUIContext.ids,
      setIds: organizationsUIContext.setIds,
      queryParams: organizationsUIContext.queryParams,
      setQueryParams: organizationsUIContext.setQueryParams,
      openEditOrganizationDialog: organizationsUIContext.openEditOrganizationDialog,
      openDeleteOrganizationDialog: organizationsUIContext.openDeleteOrganizationDialog,
    };
  }, [organizationsUIContext]);


  const selectOptionsArr = [{
    value: false,
    label: 'Active'
  }, {
    value: true,
    label: 'InActive'
  }];
  // Getting curret state of organizations list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.organizations }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Organizations Redux state
  useEffect(() => {
    // clear selections list
    organizationsUIProps.setIds([]);
    // server call by queryParams
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationsUIProps.queryParams, entities]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    {
      dataField: "primaryContactName",
      text: "Primary Contact",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    {
      dataField: "primaryEmail",
      text: "Primary Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    {
      dataField: "primaryMobile",
      text: "Primary Mobile",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    {
      dataField: "deleted",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.StatusColumnFormatter,//(cell, row) => (row.deleted? 'InActive': 'Active'),
      filter: selectFilter({
        options: selectOptionsArr,
        defaultValue: ''
      })
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditOrganizationDialog: organizationsUIProps.openEditOrganizationDialog,
        openDeleteOrganizationDialog: organizationsUIProps.openDeleteOrganizationDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: organizationsUIProps.queryParams.pageSize,
    page: organizationsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                // remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  organizationsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: organizationsUIProps.ids,
                //   setIds: organizationsUIProps.setIds,
                // })}
                filter={filterFactory()}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
