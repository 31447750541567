import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  // sortCaret,
  // headerSortingClasses,
} from "../../../_metronic/_helpers";
import { Pagination } from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-toastify/dist/ReactToastify.css";
import "../../../styles/applicationTable.scss";
import {connect} from 'react-redux';
import { fetchAppCoupon } from "../../pages/Applications/_redux/organizationsActions";
import { Button, Modal, Paper, TextField } from "@material-ui/core";
import CouponScanner from "./CouponScanner";


const CouponsTable=({ ...props}) =>{
  const history = useHistory();
  const [qrModal, setqrModal] = useState(false);
  const [bookingTrue, setbookingTrue] = useState(false);
  const [entities, setentities] = useState(false);
  // const entities = [
  //   {
  //     id: '0', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '223456789', phone: '9898989899',
  //     qr: <div onClick={()=>{
  //       setqrModal(true)
  //     }}>
  //       <img src="https://cdn.iconscout.com/icon/premium/png-256-thumb/qr-code-scan-640182.png" alt="" width={40}/>
  //     </div>
  //   },
  //   {
  //     id: '1', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '323456789', phone: '9898989899' ,
  //     qr: <div>
  //     <img src="https://cdn.iconscout.com/icon/premium/png-256-thumb/qr-code-scan-640182.png" alt="" width={40}/>
  //   </div>
  //   },
  //   {
  //     id: '2', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '423456789', phone: '9898989899'
  //   },
  //   {
  //     id: '3', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '523456789', phone: '9898989899'
  //   },
  //   {
  //     id: '4', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '623456789', phone: '9898989899'
  //   },
  // ];

  useEffect(() => {
    setentities(
     props.appCoupon?.map((d) => {
        return {
          ...d,
          qrImage: (
            <div
              onClick={() => {
                setqrModal(true);
              }}
            >
              <img src={d.qrImage} style={{ height: "50px", width: "50px" }} />
            </div>
          ),
        };
      })
    );
  }, [props.appCoupon]);

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      // history.push(`/retailer-application/${rowIndex}`);
    },
  };
  const customHeaderTemplate = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: "20px" }}>
          <span>
            {column.text} {sortElement}
          </span>
        </div>
        <div>{filterElement}</div>
      </div>
    );
  };
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   accessor: "link",
    //   // sortCaret: sortCaret,
    //   // headerSortingClasses,
    // },
    {
      dataField: "couponCode",
      text: "CouponCode",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    //   // sortCaret: sortCaret,
    //   // headerSortingClasses,
    //   filter: textFilter(),
    //   headerFormatter: customHeaderTemplate,
    // },
    // {
    //   dataField: "mobile",
    //   text: "Phone Number",
    //   sort: true,
    //   // sortCaret: sortCaret,
    //   // headerSortingClasses,
    //   filter: textFilter(),
    //   headerFormatter: customHeaderTemplate,
    // },
    {
      dataField: "discountPercent",
      text: "Discount Percent",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "minPurchaseAmt",
      text: "Min Purchase Amount",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "generatedDate",
      text: "Generated Date",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
      formatter: (cell) => {
        return (
          <>
            {moment(cell).format("LL")}
            <br />
            {moment(cell).format("hh:mm A")}
          </>
        );
      },
    },
    {
      dataField: "expireDate",
      text: "Expire Date",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
      formatter: (cell) => {
        return (
          <>
            {moment(cell).format("LL")}
            <br />
            {moment(cell).format("hh:mm A")}
          </>
        );
      },
    },
    // {
    //   dataField: "qrImage",
    //   text: "Scan Qr Code",
    //   sort: true,
    //   // sortCaret: sortCaret,
    //   // headerSortingClasses,
    // },
  ];

  const PaginationOptions = {
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };

  return (
    <>
      <CouponScanner />
      <PaginationProvider pagination={paginationFactory(PaginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
        
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-align-top-left cursor"
                bootstrap4
                keyField="id"
                hover={true}
                data={entities === null ? [] : entities}
                columns={columns}
                rowEvents={tableRowEvents}
                onTableChange={getHandlerTableChange()}
                filter={filterFactory()}
                {...paginationTableProps}
              ></BootstrapTable>
         
          );
        }}
      </PaginationProvider>
     {/* <Modal
        className="d-flex justify-content-center align-items-center w-100 h-100"
        open={qrModal}
        onClose={() => setqrModal(false)}
      >
        <Paper className={bookingTrue ? "p-5" : ""}>
          {bookingTrue ? (
            <>
              <TextField
                label="Coupon Code"
                // value={values.name}
                // onChange={handleChange('name')}
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Mobile Number"
                // value={values.name}
                // onChange={handleChange('name')}
                margin="normal"
                variant="outlined"
              />
            </>
          ) : (
            <img
              style={{ maxWidth: "400px" }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png"
              alt=""
            />
          )}

          <div className="text-center font-weight-bold">OR</div>

          {bookingTrue ? (
            <div
              onClick={() => setbookingTrue(false)}
              className="d-flex justify-content-center mb-5 mt-3"
            >
              <Button variant="contained" color="secondary">
                Scan QR Code
              </Button>
            </div>
          ) : (
            <div
              onClick={() => setbookingTrue(true)}
              className="d-flex justify-content-center mb-5 mt-3"
            >
              <Button variant="contained" color="secondary">
                Click here to enter coupon code
              </Button>
            </div>
          )}
        </Paper>
          </Modal>*/}
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  appCoupon: state.applications.appCoupon,
  
});

const mapActionsToProps = {
  fetchAppCoupon,
};
export default connect(mapStateToProps, mapActionsToProps)(CouponsTable);
