import { createSlice } from "@reduxjs/toolkit";

const initialHolidaysState = {
    loading: false,
    holidays: [],
    slots: [],
    success: '',
    error: '',
    selectedHoliday: null
};

export const holidaysSlice = createSlice({
    name: "holidays",
    initialState: initialHolidaysState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.payload.error}`;
            state.loading = false;
        },
        catchSuccessMessage: (state, action) => {
            state.success = `${action.payload.success}`;
            state.loading = false;
        },
        startCall: (state) => {
            state.error = null;
            state.loading = true;
        },
        // fetch holidays
        holidaysFetched: (state, action) => {
            state.loading = false;
            state.holidays = action.payload.holidays;
            state.error = null;
        },
        // create holiday
        holidayCreated: (state, action) => {
            state.loading = false;
            state.success = action.payload.success;
            state.holidays = [...state.holidays, action.payload.holiday];
            state.error = null;
        },
         // update holiday
         holidayUpdated: (state, action) => {
            state.loading = false;
            state.success = action.payload.success;
            // state.holidays = [...state.holidays, action.payload.holiday];
            state.error = null;
        },
        // fetach holiday
        holidayFetched: (state, action) => {
            state.loading = false;
            state.success = action.payload.success;
            state.selectedHoliday = action.payload.holiday;
            state.error = null;
        },

    }
});
