import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import { shallowEqual, useSelector } from "react-redux";
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import { Pagination } from "../../../_metronic/_partials/controls";
// //import * as uiHelpers from "../ApplicationsUIHelpers";
// //import * as columnFormatters from "./column-formatters";
// import { Pagination } from "../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAppAssignedCoupon } from "../../pages/Applications/_redux/organizationsActions";
import { Redirect } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
//import { useApplicationsUIContext } from "../ApplicationsUIContext";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import SVG from "react-inlinesvg";
import { TextField, Grid } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* ========= style for form ========= */
import "../../../styles/applicationTable.scss";

function AssignedCoupons({ ...props }) {
  const history = useHistory();
  const { id } = useParams();
  const selectOptionsArr = [
    {
      value: false,
      label: "Active",
    },
    {
      value: true,
      label: "InActive",
    },
  ];

  useEffect(() => {
   
    props.fetchAppAssignedCoupon(id);

  }, []);
  console.log("props", props.appAssignedCoupon)
  
  // const entities = [
  //   {
  //     name: "John",
  //     email: "john@example.com",
  //     phoneNo: "123",
  //     availed: "true"
  //   },
  //   {
  //     name: "John",
  //     email: "john@example.com",
  //     phoneNo: "123",
  //     availed: "true"
  //   },
  //   {
  //     name: "John",
  //     email: "john@example.com",
  //     phoneNo: "123",
  //     availed: "true"
  //   },

  // ]

  const columns = [

    {
      dataField: "name",
      text: "Name",
      sort: true,

      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    {
      dataField: "phoneNo",
      text: "Phone Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
      // formatter: (cell) => {
      
      //   const buff = new Buffer(cell, 'base64');
      //   const base64ToStringNew = buff.toString('ascii');
      //   console.log("string",base64ToStringNew)
      //   return (
      //     <>
      //    {base64ToStringNew} 
      
      //     </>
      //   );
      // }
     
     },
     
     ];

    //  var base64 = 'SGVsbG8gd29ybGQ=';
    //  var words = CryptoJS.enc.Base64.parse(base64);
    //  var textString = CryptoJS.enc.Utf8.stringify(words);
    //  console.log("string",textString);

  //Table pagination properties

  // const paginationOptions = {
  //   custom: true,
  //   //totalSize: totalCount,
  //   totalSize: "0",
 
  // };
  const paginationOptions = {
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <Pagination
            //   //isLoading={listLoading}
            //   paginationProps={paginationProps}
            // >
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-align-top-left cursor"
              bootstrap4
              // remote
              keyField="id"
              hover={true}
              data={props.appAssignedCoupon == null ? [] : props.appAssignedCoupon}
              columns={columns}

              //defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange()
                //applicationsUIProps.setQueryParams
              }
              // selectRow={getSelectRow({
              //   entities,
              //   ids: applicationsUIProps.ids,
              //   setIds: applicationsUIProps.setIds,
              // })}
              filter={filterFactory()}
              {...paginationTableProps}
            >
              {/*<PleaseWaitMessage entities={entities} />*/}
              {/*<NoRecordsFoundMessage entities={entities} />*/}
            </BootstrapTable>
            // </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  appAssignedCoupon: state.applications.appAssignedCoupon,

});

const mapActionsToProps = {
  fetchAppAssignedCoupon,
};
export default connect(mapStateToProps, mapActionsToProps)(AssignedCoupons);
