import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./OrganizationsUIHelpers";

const OrganizationsUIContext = createContext();

export function useOrganizationsUIContext() {
  return useContext(OrganizationsUIContext);
}

export const OrganizationsUIConsumer = OrganizationsUIContext.Consumer;

export function OrganizationsUIProvider({ organizationsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initOrganization = {
    id: undefined,
    name: "",
    primaryContactName: "",
    primaryEmail: "",
    primaryMobile: "",
    deleted: false,
    duration: 0
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initOrganization,
    newOrganizationButtonClick: organizationsUIEvents.newOrganizationButtonClick,
    openEditOrganizationDialog: organizationsUIEvents.openEditOrganizationDialog,
    openDeleteOrganizationDialog: organizationsUIEvents.openDeleteOrganizationDialog,
    openDeleteOrganizationsDialog: organizationsUIEvents.openDeleteOrganizationsDialog,
    openFetchOrganizationsDialog: organizationsUIEvents.openFetchOrganizationsDialog,
    openUpdateOrganizationsStatusDialog: organizationsUIEvents.openUpdateOrganizationsStatusDialog
  };

  return <OrganizationsUIContext.Provider value={value}>{children}</OrganizationsUIContext.Provider>;
}