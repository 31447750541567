import axios from "axios";
import __env from "../../../../env";
export function CreateOffer(formData) {
  return axios.post(
    `${__env.dataApi}/appointment/createAppointment`,
    formData,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
}

export function getALLOffers(token) {
  return axios.get(`${__env.dataApi}/adminDashboard/getAppointments`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}
export function fetchOffers(appid) {
  return axios.get(`${__env.dataApi}/retailer/getRetailerByAppId/${appid}`);
}
