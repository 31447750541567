import { createSlice } from "@reduxjs/toolkit";

const initialRetailerState = {
    loading: false,
    success: '',
    error: '',
    getRetailers:[]
   
};

export const retailerSlice = createSlice({
    name: "retailer",
    initialState: initialRetailerState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.payload.error}`;
            state.loading = false;
        },
        catchSuccessMessage: (state, action) => {
            state.success = `${action.payload.success}`;
            state.loading = false;
        },
        startCall: (state) => {
            state.error = null;
            state.loading = true;
        },
        // coupon
        retailersData: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.getRetailers = action.payload.retailerResponse;
            state.error = null;
        },
       
        }
});
