/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import InvalidMeetingPage from "./pages/InvalidMeetingPage";

import CouponPage from "./pages/Coupon/CouponPage";
import BookingPage from "./pages/Booking/BookingPage";
import OfferPage from "./pages/Offer/OfferPage";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  //   const appid = "c5bfa72b-59df-46d4-9173-3d6e48c5940b";
  //   const couponcode = "624e7ba140cf161c089fe995";

  return (
    <Switch>
      <Route path="/page/coupon/:appid/:couponcode">
        <CouponPage />
      </Route>
      {/* <Route
         path={`/page/coupon/${appid}/${couponcode}`}
         component={CouponPage}
       /> */}
      <Route path="/page/booking/:appid/:couponcode" component={BookingPage} />
      <Route path="/page/offer/:appid/:couponCode" component={OfferPage} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
          <Redirect to="/auth" />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      {/* <Route path="/page/template" component={TemplatePage} />*/}
      <Route path="/invalid_meeting" component={InvalidMeetingPage} />

      <Route path="/logout" component={Logout} />
      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
