import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import { shallowEqual, useSelector } from "react-redux";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../../../pages/AppList/AppUIHelpers";
import { shallowEqual, connect, useSelector } from "react-redux";
import {
  FetchAllApps,
  AssigningApps,
} from "../../../pages/AppList/redux/appAction";

import { fetchRetailers } from "../../../pages/Applications/_redux/retailerAction";
//import * as uiHelpers from "../ApplicationsUIHelpers";
//import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
//import { useApplicationsUIContext } from "../ApplicationsUIContext";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { TextField, Grid, Button } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* ========= style for form ========= */
import "../../../../styles/applicationTable.scss";
import { getApplicationDetailData } from "../../../pages/Applications/_redux/organizationsCrud";

function AllApps({ auth, ...props }) {
  const history = useHistory();
  const [disable, setDisable] = useState(true);
  const [appId, setAppId] = useState([]);
  const [value, setValue] = useState("Select retailer to assign app");

  const { currentState } = useSelector(
    (state) => ({ currentState: state.allApps }),
    shallowEqual
  );

  const selectOptionsArr = [
    {
      value: false,
      label: "Active",
    },
    {
      value: true,
      label: "InActive",
    },
  ];


  

  useEffect(() => {
    if (auth) {
      props.FetchAllApps(auth.authToken);
    }
  }, [auth, FetchAllApps]);

  useEffect(() => {
    if (auth) {
      props.fetchRetailers(auth.authToken);
    }
    console.log("props", props.retailers);
  }, [auth, fetchRetailers]);

  const handleClick = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success("Copied!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: undefined,
        });
      });
  };

  const selectRow = {
    mode: "checkBox",
    clickToSelect: true,
    color: "#def3ff",
    onSelect: (row, isSelected, e) => {
      if (isSelected) {
        const ids = new Set([...appId, row.appId]);
        setAppId(Array.from(ids));
        console.log("appid", ids);
        setDisable(false);
        console.log(isSelected);
        console.log("value", e);
      } else {
        setAppId(appId.filter((id) => id !== row.appId));
      }
    },

    // onSelect: (row, isSelect, rowIndex, e) => {
    //   if (isSelect === row.appId) {
    //     setAppId((rowData) => [...rowData, row.appId]);
    //     setAppId([isSelect]);
    //     setDisable(false)
    //     console.log("issekect", row.appId);
    //   } else {
    //     setAppId((rowData) => rowData.filter((x, i) => i !== rowIndex));
    //     setAppId([]);
    //   }
    // },
  };
  const handleSelect = (id) => {
    console.log(id);
    setValue(id);
  };

  const handleApps = () => {
    if (auth) {
      props.AssigningApps(auth.authToken, appId, value);
      toast.success("Apps assigned  successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      //history.push("/retailers");
    }
  };

  const customHeaderTemplate = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: "20px" }}>
          <span>
            {column.text} {sortElement}
          </span>
        </div>
        <div>{filterElement}</div>
      </div>
    );
  };

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   accessor: "link",
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },

    {
      dataField: "appName",
      text: "AppName",
      sort: true,
      accessor: "link",
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "tagLine",
      text: "TagLine",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "categories",
      text: "Category",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "assigned",
      text: "Assigned",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "appId",
      text: "AppID",
      formatter: (cell, row) => {
        return (
          <Grid container={true} style={{ width: "100%" }}>
            <div style={{ display: "block" }}>

              <Link to={`/create-coupon/${row.appId}`} style={{ color: 'blue' }}>
                Create Coupon
              </Link>
            </div>
            <div style={{ display: "flex", width: "100px" }}>
              <TextField
                disabled
                id="filled-disabled"
                defaultValue={row.appId}
                variant="filled"
                type="password"
                style={{ borderTopRightRadius: "0px", width: "60%" }}
              />
              <div className="symbol symbol-50 symbol-light mr-1">
                <span
                  className="symbol-label"
                  style={{
                    backgroundColor: "#f5f7fa",
                    borderRadius: "0",
                    height: "39.44px",
                    borderTopRightRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(row.appId)}
                >
                  <img
                    src={toAbsoluteUrl("/media/svg/icons/Media/copy.png")}
                    className="h-50 align-self-center"
                  />
                  <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    transition={Slide}
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                  />
                </span>
              </div>
            </div>
          </Grid>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120%",
      },
    },
  ];
  // Table pagination properties
  const PaginationOptions = {
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(PaginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <Pagination
            //   // isLoading={loading}
            //   paginationProps={paginationProps}
            // >
            <BootstrapTable
              mode="radio"
              size="sm"
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-align-top-left cursor"
              bootstrap4
              hover={true}
              keyField="id"
              data={props.allApps === null ? [] : props.allApps}
              columns={columns}
              selectRow={selectRow}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={
                getHandlerTableChange()
                //applicationsUIProps.setQueryParams
              }
              // selectRow={getSelectRow({
              //   entities,
              //   ids: applicationsUIProps.ids,
              //   setIds: applicationsUIProps.setIds,
              // })}
              filter={filterFactory()}
              {...paginationTableProps}
            >
              {/*<PleaseWaitMessage entities={entities} />*/}
              {/*<NoRecordsFoundMessage entities={entities} />*/}
            </BootstrapTable>
            // </Pagination>
          );
        }}
      </PaginationProvider>
      <div className="text-right">
        <div>
          {/* <h4>Choose Retailer</h4> */}
          <DropdownButton
            fullWidth={true}
            alignLeft
            title={value}
            id="dropdown-basic-button"
            className="d-inline mt-6 mr-5"
            variant="secondary"
            onSelect={handleSelect}
          >
            {props.retailers && props.retailers ? (
              props.retailers.map((item, index) => (
                <Dropdown.Item eventKey={item.retailer.id}>
                  {item.name}
                </Dropdown.Item>
              ))
            ) : (
              <div>Loading ...</div>
            )}
          </DropdownButton>
          <button
            type="button"
            className="btn btn-success"
            data-toggle="modal"
            disabled={disable}
            onClick={handleApps}
            data-target="#exampleModalLong"
          >
            Assign
          </button>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.apps.error,
  success: state.apps.success,
  loading: state.apps.loading,
  allApps: state.apps.allApps,
  apps_assigning: state.apps.apps_assigning,
  retailers: state.retailer.getRetailers,
  auth: state.auth,
});

const mapActionsToProps = {
  FetchAllApps,
  AssigningApps,
  fetchRetailers,
};
export default connect(mapStateToProps, mapActionsToProps)(AllApps);
