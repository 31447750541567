// ROLE_USER, ROLE_CUSTOMER, ROLE_EXPERT, ROLE_ADMIN, ROLE_SUPER_ADMIN;
const rules = {
    ROLE_RETAILER: {
        static: ["dashboard-page:visit"],
        home: "/applications"
    },
    ROLE_USER: {
        static: ["bookings-page:visit"],
        home: "/bookings"
    },
    ROLE_CUSTOMER: {
        static: ["bookings-page:visit"],
        home: "/bookings"
    },
   
    ROLE_EXPERT: {
        static: [
            "bookings-page:visit",
            "holidays-list:visit"
        ],
        home: "/bookings"
    },
    ROLE_ADMIN: {
        static: [
            "bookings-page:visit",
            "manage-lists:visit",
            "experts-list:visit",
        ],
        home: "/experts"
        // dynamic: {
        //     "posts:edit": ({ userId, postOwnerId }) => {
        //         if (!userId || !postOwnerId) return false;
        //         return userId === postOwnerId;
        //     }
        // }
    },
    ROLE_SUPER_ADMIN: {
        static: [
            //"bookings-page:visit",
            "retailers-page:visit",
            "users-list:visit",
            "organizations-list:visit"
        ],
        home: "/retailers"
    }
};

export default rules;
/* resource:action */


// src: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/const 
