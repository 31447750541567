import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import { shallowEqual, useSelector } from "react-redux";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
//import * as uiHelpers from "../ApplicationsUIHelpers";
//import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchRetailers } from "../_redux/retailerAction";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
//import { useApplicationsUIContext } from "../ApplicationsUIContext";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import SVG from "react-inlinesvg";
import { TextField, Grid } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* ========= style for form ========= */
import "../../../../styles/applicationTable.scss";

function ApplicationsTable({ auth, ...props }) {
  const history = useHistory();

  const selectOptionsArr = [
    {
      value: false,
      label: "Active",
    },
    {
      value: true,
      label: "InActive",
    },
  ];

  useEffect(() => {
    if (auth.user) {
      props.fetchRetailers(auth.authToken);
    }
  }, [auth, fetchRetailers]);
  const tableRowEvents = {
    onClick: (e, row) => {
      history.push(`/applist/${row.retailer.id}`);
      console.log("props.retailers.id", row.retailer.id);
    },
  };

  // const handleClick = (id) => {
  //   navigator.clipboard
  //     .writeText(id)
  //     .then(() => {
  //       toast.success('Copied!', {
  //         position: "top-center",
  //         autoClose: 1000,
  //         hideProgressBar: true,
  //         pauseOnHover: true,
  //         progress: undefined,
  //       });
  //     })
  //     .catch(() => {
  //       toast.error('Something went wrong!', {
  //         position: "top-center",
  //         autoClose: 1000,
  //         hideProgressBar: true,
  //         pauseOnHover: true,
  //         progress: undefined,
  //       });
  //     });
  // }
  // Getting curret state of applications list from store (Redux)
  //const { currentState } = useSelector(
  //(state) => ({ currentState: state.applications }),
  //shallowEqual
  //);
  //const { totalCount, entities, listLoading } = currentState;

  // Applications Redux state
  //useEffect(() => {
  // clear selections list
  //applicationsUIProps.setIds([]);
  // server call by queryParams
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [applicationsUIProps.queryParams, entities]);
  // Table column

  const columns = [
    // {
    //   dataField: "retailer.id",
    //   text: "Retailer ID",
    //   sort: true,
    //   accessor: "link",
    //   sortCaret: sortCaret,
    //   headerSortingClasses,

    // },
    {
      dataField: "retailer.logo",
      dataFormat: "imageFormatter",
      text: "Logo",
      sort: true,
      accessor: "link",
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    {
      dataField: "mobile",
      text: "Phone Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    {
      dataField: "retailer.retailerImage",
      text: "RetailerImage",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },

    // {
    //   dataField: "appid",
    //   text: "AppID",
    //   formatter: (cell,row) => {
    //     return (
    //       <Grid container={true} style={{width:"120%"}}>
    //         <TextField
    //               disabled
    //               id="filled-disabled"
    //               defaultValue={row.appid}
    //               variant="filled"
    //               type="password"
    //               style={{borderTopRightRadius: "0px"}}
    //         />
    //         <div className="symbol symbol-50 symbol-light mr-1">
    //             <span
    //             className="symbol-label"
    //             style={{
    //                 backgroundColor:"#f5f7fa",
    //                 borderRadius:"0",
    //                 height: "39.44px",
    //                 borderTopRightRadius: "4px",
    //                 cursor: "pointer",
    //             }}
    //             onClick={() => handleClick(row.appid)}
    //             >
    //                 <img
    //                     src={toAbsoluteUrl("/media/svg/icons/Media/copy.png")}
    //                     className="h-50 align-self-center"
    //                 />
    //                 <ToastContainer position="top-center"
    //                   autoClose={1000}
    //                   hideProgressBar={true}
    //                   newestOnTop={false}
    //                   transition={Slide}
    //                   rtl={false}
    //                   pauseOnFocusLoss
    //                   pauseOnHover
    //                 />
    //             </span>
    //         </div>
    //       </Grid>
    //     );
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "120%",
    //   },
    // },
  ];
  // Table pagination properties

  // const paginationOptions = {
  //   custom: true,
  //   //totalSize: totalCount,
  //   totalSize: "0",
  //   //sizePerPageList: uiHelpers.sizePerPageList,
  //   //sizePerPage: applicationsUIProps.queryParams.pageSize,
  //   //page: applicationsUIProps.queryParams.pageNumber,
  // };
  const PaginationOptions={
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(PaginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <Pagination
            //   //isLoading={listLoading}
            //   paginationProps={paginationProps}
            // >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-align-top-left cursor"
                bootstrap4
                // remote
                keyField="id"
                hover={true}
                data={
                  props.retailers === null
                    ? []
                    : props.retailers.map((d) => {
                        return {
                          ...d,
                          retailer: {
                            ...d.retailer,
                            retailerImage: (
                              <img
                                src={d.retailer?.retailerImage}
                                style={{ height: "40px", width: "70px" }}
                              />
                            ),
                            logo: (
                              <img
                                src={d.retailer?.logo}
                                style={{ height: "50px", width: "70px" }}
                              />
                            ),
                          },
                        };
                      })
                }
                columns={columns}
                rowEvents={tableRowEvents}
                //defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange()
                //applicationsUIProps.setQueryParams
                }
              
                filter={filterFactory()}
                {...paginationTableProps}
              >
                
              </BootstrapTable>
            // </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.retailer.error,
  success: state.retailer.success,
  loading: state.retailer.loading,
  retailers: state.retailer.getRetailers,
  auth: state.auth,
});

const mapActionsToProps = {
  fetchRetailers,
};
export default connect(mapStateToProps, mapActionsToProps)(ApplicationsTable);