export const OrganizationStatusCssClasses = ["danger", "success", "info", ""];
export const OrganizationStatusTitles = ["Active", "InActive", ""];
export const OrganizationTypeCssClasses = ["success", "primary", ""];
export const OrganizationTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
  { text: "40", value: 40 },
  { text: "50", value: 50},
];
export const initialFilter = {
  filter: {
    name: "",
    email: "",
   
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 30
};
