import axios from "axios";
import __env from "../../../../env";
export function CreateBooking(formData) {

    return axios.post(`${__env.dataApi}/appointment/createAppointment`, 
formData,
{
"headers": {
            "content-type": "application/json",
          }
    });
}

export function getALLBookings(token) {

    return axios.get(`${__env.dataApi}/adminDashboard/getAppointments`, { headers: { Authorization: `Bearer ${token || ''}` } });
}
export function fetchBookings(appid) {
    return axios.get(`${__env.dataApi}/retailer/getRetailerByAppId/${appid}`);
}

