import React, { useEffect } from "react";
import {
  LineChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import {format} from 'date-fns';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { fetchCouponGraph } from '../../pages/Applications/_redux/organizationsActions';

const data=[
  { 
    date:"Apr 23",
    dataCount:3
  },
  { 
    date:"Apr 24",
    dataCount:2
  },
  { 
    date:"Apr 25",
    dataCount:1
  },
  { 
    date:"Apr 26",
    dataCount:4
  },
  { 
    date:"Apr 27",
    dataCount:5
  },
]

const CouponsChart = ({ auth, ...props }) => {
  const { id } = useParams();
 const dateFormatter =(dates) => {
    return moment(dates).format("MMM D");
  };
  
  // static demoUrl = "https://codesandbox.io/s/simple-area-chart-4ujxw";
  // const date = moment(dates).format(' LL')
  useEffect(() => {
    if (auth) {
      props.fetchCouponGraph(id,auth.authToken)
     
    }
    console.log("coupondata", props.appCouponData)
  }, [])
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={500}
        height={400}
        data={props.appCouponData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" 
        tickFormatter={dateFormatter}
       />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="dataCount" stroke="#1d738b" fill="#1d738b" />
      </LineChart>
    </ResponsiveContainer>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  appCouponData: state.applications.appCouponData,
  auth: state.auth

})

const mapActionsToProps = {
    fetchCouponGraph

};
export default connect(mapStateToProps, mapActionsToProps)(CouponsChart);
