import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Input } from "../../../../_metronic/_partials/controls";
import { FormInput } from "../../../../_metronic/_partials/controls";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Coupon,
  fetchCoupon,
  fetchAppData,
} from "../../Coupon/redux/couponAction";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@material-ui/core";

const CouponForm = ({ ...props }) => {
  const { intl } = props;
  const appId = props.appId;
  var logo = "/media/logos/store_logo.jpg";
  var image = "/media/logos/store_image.jpg";
  var openTime = "00:00";
  var closeTime = "00:00";
  const phoneRegExp = /^[0-9]\d{9}$/;
  const numberRegExp = /^\d+$/;
  const [loading, setLoading] = useState(false);

  const handleCoupon = (values, { resetForm }) => {
    const formData = {
      ...values,
    };
    resetForm();
    if (formData) {
      setLoading(true);
      props
        .Coupon(formData, appId)
        .then((response) => {
          console.log(JSON.stringify(response));
          values = {
            email: "",
            mobile: "",
            minPurchaseAmt: "",
            discountPercent: "",
          };
          toast.success("Coupon created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    props.fetchCoupon();
    props.fetchAppData();
  }, []);

  if (props.appData) {
    if (props.appData.user.retailer.logo) {
      logo = props.appData.user.retailer.logo;
    }
    if (props.appData.user.retailer.retailerImage) {
      image = props.appData.user.retailer.retailerImage;
    }
    if (props.appData.app.openTime) {
      openTime = props.appData.app.openTime;
    }
    if (props.appData.app.closeTime) {
      closeTime = props.appData.app.closeTime;
    }
  }

  return (
    <div>
      {
        <>
          <div className="row">
            <div className="col-lg-6 m-auto">
              <div className="text-center mt-2 mb-lg-2">
                <div className="card-header">
                  <img
                    src={toAbsoluteUrl(image)}
                    className="h-50 align-self-center"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                  <div
                    style={{
                      float: "right",
                      position: "absolute",
                      zIndex: "1",
                      width: "87%",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        marginTop: "-48%",
                        border: "2px solid black",
                        float: "left",
                      }}
                    >
                      <img
                        src={toAbsoluteUrl(logo)}
                        className="h-50 align-self-center"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {props.appData && (
                <div className="text-center mt-2 mb-lg-2">
                  <div className="card-header">
                    <h4 className="text-dark font-weight-bold">
                      {props.appData.app.tagLine
                        ? props.appData.app.tagLine
                        : "Tag Line not found"}
                    </h4>
                    <p className="text-dark font-weight-bold">
                      {props.appData.app.description
                        ? props.appData.app.description
                        : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
                    </p>
                    {props.couponData && (
                      <p className="text-dark font-weight-bold">
                        Get flat{" "}
                        {props.couponData.maxPercentageOff
                          ? props.couponData.maxPercentageOff
                          : "0"}{" "}
                        % off on minimum purchase of ₹{" "}
                        {props.couponData.minPurchasingAmt
                          ? props.couponData.minPurchasingAmt
                          : "0"}
                        .
                      </p>
                    )}
                  </div>
                </div>
              )}
              <Formik
                //enableReinitialize={true}
                //initialValues={{}}
                initialValues={{
                  email: "",
                  mobile: "",
                  minPurchaseAmt: "",
                  discountPercent: "",
                }}
                /*validationSchema={Yup.object().shape({
                                    name: Yup.string().required('name is required'),
                                    email: Yup.string()
                                        .email("Invalid email address format")
                                        .required("Email is required"),
                                    mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
                                })}*/
                validate={(values) => {
                  const errors = {};
                  // if (!values.name) {
                  //   // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                  //   errors.name = intl.formatMessage({
                  //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  //   });
                  // }
                  if (!values.email) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_FIELD",
                    });
                  }

                  if (!values.mobile) {
                    errors.mobile = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  } else if (!phoneRegExp.test(values.mobile)) {
                    errors.mobile = intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_FIELD",
                    });
                  }

                  if (!values.minPurchaseAmt) {
                    errors.minPurchaseAmt = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  } else if (!numberRegExp.test(values.minPurchaseAmt)) {
                    errors.minPurchaseAmt = intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_FIELD",
                    });
                  }

                  if (!values.discountPercent) {
                    errors.discountPercent = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  } else if (!numberRegExp.test(values.discountPercent)) {
                    errors.discountPercent = intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_FIELD",
                    });
                  }
                  return errors;
                }}
                onSubmit={handleCoupon}
              >
                {({
                  errors,
                  status,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form
                    className="mt-4 mb-4"
                    noValidate={true}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    <div className="card">
                      <div className="card-body">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <Field
                              name="email"
                              component={Input}
                              placeholder="Email"
                              label="Email"
                              value={values.email}
                              onChange={handleChange}
                              withFeedbackLabel={false}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <Field
                              name="mobile"
                              component={Input}
                              placeholder="Mobile"
                              label=" Mobile Number"
                              onChange={handleChange}
                              value={values.mobile}
                              withFeedbackLabel={false}
                              maxLength="10"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <Field
                              name="minPurchaseAmt"
                              component={Input}
                              placeholder="Minimum purchase amount"
                              label=" Minimum Purchase Amount"
                              onChange={handleChange}
                              value={values.minPurchaseAmt}
                              withFeedbackLabel={false}
                              maxLength="10"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <Field
                              name="discountPercent"
                              component={Input}
                              placeholder="Discount percentage"
                              label=" Discount Percentage"
                              onChange={handleChange}
                              value={values.discountPercent}
                              withFeedbackLabel={false}
                              maxLength="2"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <button
                              type="submit"
                              disabled={loading}
                              className="btn btn-primary btn-elevate"
                              endIcon={
                                loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : (
                                  ""
                                )
                              }
                              style={{ width: "100%" }}
                            >
                              Get Coupon
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <p style={{ fontSize: "12px" }}>
                          <span style={{ color: "red" }}>*</span> RetailerName
                          will be open from {openTime} to {closeTime}.
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </>
      }
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.coupons.error,
  success: state.coupons.success,
  loading: state.coupons.loading,
  coupon: state.coupons.coupon,
  // fetchCoupon: state.calendar.fetchCoupon,
  couponData: state.coupons.fetchCoupon,
  appData: state.coupons.fetchApp,
});

const mapActionsToProps = {
  Coupon,
  fetchCoupon,
  fetchAppData,
};
//export default connect(mapStateToProps, mapActionsToProps)(CouponForm);
export default injectIntl(
  connect(mapStateToProps, mapActionsToProps)(CouponForm)
);
