import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-toastify/dist/ReactToastify.css";
import "../../../../styles/applicationTable.scss";
import { connect } from "react-redux";
import { fetchRetailer } from "../_redux/organizationsActions";
export function ApplicationsTable({ fetchRetailer, applications, auth }) {
  useEffect(() => {
    if (auth.user) {
      fetchRetailer(auth.user?.retailer.appId, auth.authToken);
    }
  }, [auth, fetchRetailer]);
  const history = useHistory();
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/retailer-application/${row.appId}`);
    },
  };

  const customHeaderTemplate = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: "20px" }}>
          <span>
            {column.text} {sortElement}
          </span>
        </div>
        <div>{filterElement}</div>
      </div>
    );
  };

  const columns = [
    // {
    //   dataField: "appId",
    //   text: "ID",
    //   sort: true,
    //   accessor: "link",
    //   sortCaret: sortCaret,
    //   headerSortingClasses,

    // },
    {
      dataField: "appName",
      text: "Name",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "tagLine",
      text: "Tag Line",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
  ];
  // const paginationOptions = {
  //   custom: true,
  //   totalSize: '0',
  // };
  return (
    <>
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              paginationProps={paginationProps}
            > */}
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-align-top-left cursor"
        bootstrap4
        keyField="id"
        hover={true}
        data={applications.retailerapplications || []}
        columns={columns}
        rowEvents={tableRowEvents}
        onTableChange={getHandlerTableChange()}
        filter={filterFactory()}
        // {...paginationTableProps}
      ></BootstrapTable>
      {/* </Pagination>
          );
        }}
      </PaginationProvider> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    applications: state.applications,
    auth: state.auth,
  };
};

const mapActionsToProps = {
  fetchRetailer,
};

export default connect(mapStateToProps, mapActionsToProps)(ApplicationsTable);
