import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core";
import { AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import Dashboard from "./Dashboard/Dashboard";
import { Card } from "@material-ui/core";
import { CardBody } from "../../../../_metronic/_partials/controls";
import { BookingsTable } from "../../../components/RetailerApplications/BookingsTable";
import  CouponsTable from "../../../components/RetailerApplications/CouponsTable";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchAppDetail,
  fetchAppBooking,
  fetchAppCoupon,
} from "../_redux/organizationsActions";
import AssignedCoupons from "../../../components/RetailerApplications/AssignedCoupons";
import AvailedCoupons from "../../../components/RetailerApplications/AvailedCoupons";
import AssignedButNotAvailedCoupons from "../../../components/RetailerApplications/AssignedButNotAvailedCoupons";
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
  },
}));

function FullWidthTabs({
  fetchAppDetail,
  applications,
  auth,
  fetchAppBooking,
  fetchAppCoupon,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  console.log({ id });

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  function handleChangeIndex(index) {
    setValue(index);
  }

  useEffect(() => {
    console.log("fetchinggg");
    if (auth.authToken) {
      fetchAppBooking(id, auth.authToken);
      fetchAppCoupon(id, auth.authToken);
      fetchAppDetail(id, auth.authToken);
    }
  }, [auth.authToken, fetchAppDetail]);

  return (
    <div className={classes.root}>
      {/* {JSON.stringify(applications)} */}
      <AppBar position="static" color="default" full>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{ backgroundColor: "#d0e0ef", color: "#091f26" }}
        >
          <Tab label="Dashboard" />
          {/*<Tab label="Bookings" />*/}
          <Tab label="Coupons" />
          {/*<Tab label="AssignedCoupons" />
         <Tab label="AvailedCoupons"/>
  <Tab label="AssignedButNotAvailed"/>*/}

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer dir={theme.direction}>
          <div style={{ padding: 24 }}>
            {applications.appDetail && (
              <Dashboard appDetail={applications.appDetail || {}} />
            )}
          </div>
        </TabContainer>
        {/* <TabContainer dir={theme.direction}>
          <Card>
            <CardBody>
              <BookingsTable appBooking={applications?.appBooking || []} />
            </CardBody>
          </Card>
            </TabContainer>*/}

        <TabContainer dir={theme.direction}>
          <Card sx={{ padding: "20px" }}>
            <CardBody>
              <CouponsTable />
            </CardBody>
          </Card>
        </TabContainer>
        {/* <TabContainer dir={theme.direction}>
        <Card>
          <CardBody>
            <AssignedCoupons />
          </CardBody>
        </Card>
          </TabContainer>
      <TabContainer dir={theme.direction}>
      <Card>
        <CardBody>
          <AvailedCoupons />
        </CardBody>
      </Card>
    </TabContainer>
    <TabContainer dir={theme.direction}>
    <Card>
      <CardBody>
        <AssignedButNotAvailedCoupons />
      </CardBody>
    </Card>
          </TabContainer>*/}
      </SwipeableViews>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    applications: state.applications,
    auth: state.auth,
  };
};

const mapActionsToProps = {
  fetchAppDetail,
  fetchAppBooking,
  fetchAppCoupon,
};

export default connect(mapStateToProps, mapActionsToProps)(FullWidthTabs);
