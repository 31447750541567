import { Card, CardHeader } from "@material-ui/core";
import React from "react";
import { Link, Route } from "react-router-dom";
import {
  CardBody,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import ApplicationsTable from "../Applications/applications-table/RetailerApplicationsTable";

export default function RetailerDashboardPage() {
  return (
    <div>
      <Card>
        <CardHeader title="Applications">
          <CardHeaderToolbar>
            <Link to="/create-user">
              <button type="button" className="btn btn-primary">
                New Retailer
              </button>
            </Link>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ApplicationsTable />
        </CardBody>
      </Card>
    </div>
  );
}
