import { createSlice } from "@reduxjs/toolkit";

const initialAppState = {
    loading: false,
    success: '',
    error: '',
    totalCount: 0,
    retailerApps:[],
    app:null,
    apps_assigning:null,
    allApps:[],
    allCategories:[],
    allPages:[],
   
};

export const appSlice = createSlice({
    name: "app",
    initialState: initialAppState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.payload.error}`;
            state.loading = false;
        },
        catchSuccessMessage: (state, action) => {
            state.success = `${action.payload.success}`;
            state.loading = false;
        },
        startCall: (state) => {
            state.error = null;
            state.loading = true;
        },
        // coupon
        appData: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.booking = action.payload.app;
            state.error = null;
        },
        appsFetched: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.retailerApps = action.payload.appResponse;
            console.log("appsdata", state.retailerApps)
            state.error = null;
        },

       appsList: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.allApps = action.payload.appResponse;
            console.log("appsdata", state.allApps)
            state.error = null;
        },

        appCategory: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.allCategories = action.payload.appResponse;
            console.log("appsdata", state.allCategories)
            state.error = null;
        },

        fetchLandingPage: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.allPages = action.payload.appResponse;
            console.log("appsdata", state.allPages)
            state.error = null;
        },

        assigingAppsToRetailer: (state, action) => {
            console.log("action payload", action.payload)
            state.loading = false;
            state.apps_assigning = action.payload.appResponse;
            console.log("appsdata", state.apps_assigning)
            state.error = null;
        },
        }
});
