import { createSlice } from "@reduxjs/toolkit";

const initialExpertsState = {
    loading: false,
    experts: [],
    slots: [],
    success: '',
    error: '',
    selectedExpert: null
};

export const expertsSlice = createSlice({
    name: "experts",
    initialState: initialExpertsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.payload.error}`;
            state.loading = false;
        },
        catchSuccessMessage: (state, action) => {
            state.success = `${action.payload.success}`;
            state.loading = false;
        },
        startCall: (state) => {
            state.error = null;
            state.loading = true;
        },
        // fetch experts
        expertsFetched: (state, action) => {
            state.loading = false;
            state.experts = action.payload.experts;
            state.error = null;
        },
        // fetch slots
        slotsFetched: (state, action) => {
            state.loading = false;
            state.slots = action.payload.slots;
            state.error = null;
        },
        // create Expert
        expertCreated: (state, action) => {
            state.loading = false;
            state.success = action.payload.success;
            // state.experts = [...state.experts, action.payload.expert];
            state.error = null;
        },
         // update Expert
         expertUpdated: (state, action) => {
            state.loading = false;
            state.success = action.payload.success;
            // state.experts = [...state.experts, action.payload.expert];
            state.error = null;
        },
        // create Expert
        expertFetched: (state, action) => {
            state.loading = false;
            state.success = action.payload.success;
            state.selectedExpert = action.payload.expert;
            state.error = null;
        },

    }
});
