import * as requestFromServer from "./retailerCrud";
import { retailerSlice } from "./retailerSlice";
import { getErrorMessage } from "../../../utils";

const { actions } = retailerSlice;

export const PostRetailer = (data) => (dispatch) => {
    dispatch(actions.startCall());
    return requestFromServer
        .CreateRetailer(data)
        .then((response) => {
            const retailerResponse = response.data;
            dispatch(actions.retailerData({ retailerResponse }));
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            dispatch(actions.catchError({ error: errorMessage }));
        });
};

export const fetchRetailers = (token) => (dispatch) => {

    dispatch(actions.startCall());
    return requestFromServer
      .getALLRetailers(token)
      .then((response) => {
        const retailerResponse = response.data;
        console.log("bookingResponsne4", retailerResponse)
        dispatch(actions.retailersData({ retailerResponse}));
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
      });
  };