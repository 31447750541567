import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import { shallowEqual, useSelector } from "react-redux";
import {
    getHandlerTableChange,
    sortCaret,
    headerSortingClasses,
  } from "../../../_metronic/_helpers";
  import { Pagination } from "../../../_metronic/_partials/controls";
// //import * as uiHelpers from "../ApplicationsUIHelpers";
// //import * as columnFormatters from "./column-formatters";
// import { Pagination } from "../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAssignedNotAvailedCoupons } from "../../pages/Applications/_redux/organizationsActions";
import { Redirect } from "react-router-dom";
import { useHistory,useParams } from "react-router-dom";
//import { useApplicationsUIContext } from "../ApplicationsUIContext";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import SVG from "react-inlinesvg";
import { TextField, Grid } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* ========= style for form ========= */
import "../../../styles/applicationTable.scss";

function AssignedButNotAvailedCoupons({  ...props }) {
  const history = useHistory();
const {id}= useParams();
  const selectOptionsArr = [
    {
      value: false,
      label: "Active",
    },
    {
      value: true,
      label: "InActive",
    },
  ];

  useEffect(() => {
    
      props.fetchAssignedNotAvailedCoupons(id);
   
  }, []);
console.log("props",props.assignedButNotAvailedCoupon)
  const entities=[
      { 
          name: "John",
          email: "john@example.com",
          phoneNo: "123",
          availed:"true"
      },
      { 
        name: "John",
        email: "john@example.com",
        phoneNo: "123",
        availed:"true"
    },
    { 
        name: "John",
        email: "john@example.com",
        phoneNo: "123",
        availed:"true"
    },

  ]

  const columns = [
   
    {
      dataField: "name",
      text: "Name",
      sort: true,
     
      sortCaret: sortCaret,
      headerSortingClasses,
       filter: textFilter(),
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    {
      dataField: "phoneNo",
      text: "Phone Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    {
      dataField: "availed",
      text: "Availed status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
   
    
    
   
  ];
  // Table pagination properties
  const paginationOptions = {
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <Pagination
            //   //isLoading={listLoading}
            //   paginationProps={paginationProps}
            // >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-align-top-left cursor"
                bootstrap4
                // remote
                keyField="id"
                hover={true}
                data={props.assignedButNotAvailedCoupon === null ? [] : props.assignedButNotAvailedCoupon}
                columns={columns}
              
                //defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange()
                //applicationsUIProps.setQueryParams
                }
                // selectRow={getSelectRow({
                //   entities,
                //   ids: applicationsUIProps.ids,
                //   setIds: applicationsUIProps.setIds,
                // })}
                filter={filterFactory()}
                {...paginationTableProps}
              >
                {/*<PleaseWaitMessage entities={entities} />*/}
                {/*<NoRecordsFoundMessage entities={entities} />*/}
              </BootstrapTable>
          
          );
        }} 
      </PaginationProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  assignedButNotAvailedCoupon: state.applications.assignedButNotAvailedCoupon,
  
});

const mapActionsToProps = {
  fetchAssignedNotAvailedCoupons,
};
export default connect(mapStateToProps, mapActionsToProps)(AssignedButNotAvailedCoupons);
