import * as requestFromServer from "./organizationsCrud";
import { applicationsSlice, callTypes } from "./applicationsSlice";
import { getErrorMessage } from "../../../utils";

const { actions } = applicationsSlice;

export const fetchOrganizations = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrganizations()
    .then(response => {
      // const { totalCount, entities } = response.data;
      const totalCount = response.data.length;
      const entities = response.data;
      dispatch(actions.organizationsFetched({ entities, totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find organizations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrganization = id => dispatch => {
  if (!id) {
    return dispatch(actions.organizationFetched({ organizationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrganizationById(id)
    .then(response => {
      const organization = response.data;
      dispatch(actions.organizationFetched({ organizationForEdit: organization }));
    })
    .catch(error => {
      error.clientMessage = "Can't find organization";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrganization = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrganization(id)
    .then(response => {
      dispatch(actions.organizationDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete organization";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrganization = organizationForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrganization(organizationForCreation)
    .then(response => {
      const organization = response.data;
      dispatch(actions.organizationCreated({ organization }));
    })
    .catch(error => {
      error.clientMessage = "Can't create organization";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrganization = (id, organization) => dispatch => {
  return new Promise(function (resolve, reject) {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .updateOrganization(id, organization)
      .then(() => {
        dispatch(actions.organizationUpdated({ organization }));
        resolve();
      })
      .catch(error => {
        // error.clientMessage = "Can't update organization";
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const updateOrganizationsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForOrganizations(ids, status)
    .then(() => {
      dispatch(actions.organizationsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update organizations status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrganizations = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrganizations(ids)
    .then(() => {
      dispatch(actions.organizationsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete organizations";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const fetchRetailer = (appId,token) => async dispatch => {
  // dispatch(actions.retailerFetching());
  dispatch(actions.retailerapplicationsFetching());
  // try {
    // let response = await requestFromServer.getRetailerDetail('624560380d844e0c13b19722')
    // const retailer = response.data;
    // dispatch(actions.retailerFetched({ retailer }));
    // try {
      let applications = await Promise.all(
        appId.map(async(e)=>{
          let res = await requestFromServer.getApplicationDetail(e,token)
          return res?.data || {};
        })
      )
      applications = applications.filter((d)=>Object.keys(d).length >= 1)
      dispatch(actions.retailerapplicationsFetched({ applications }));
    // } catch (error) {
    //   console.log({error});
    //   dispatch(actions.errorretailerApplications({ error:'Cant fetch retailer applications' }));    
    // }
  // } catch (error) {
  //   dispatch(actions.errorretailerFetching({ error:'Cant fetch retailer detail' }));    
  // }
};


export const fetchAppDetail = (id,token) => async dispatch => {
  dispatch(actions.appDetailFetching());
    try {
      console.log({id},'adss');
      let res = await requestFromServer.getApplicationDetailData(id,token)
      console.log(res.data);
      dispatch(actions.appDetailFetched({ appDetail:res.data }));
    } catch (error) {
      dispatch(actions.errorappDetail({ error:'Cant fetch applications detail' }));    
    }
};
export const fetchAppBooking = (id,token) => async dispatch => {
  dispatch(actions.appBookingFetching());
    try {
      console.log({id},'adss');
      let res = await requestFromServer.getApplicationDetailBooking(id,token)
      console.log(res.data);
      dispatch(actions.appBookingFetched({ appBooking:res.data }));
    } catch (error) {
      dispatch(actions.errorappBooking({ error:'Cant fetch applications bookings' }));    
    }
};
export const fetchAppCoupon = (id,token) => async dispatch => {
  dispatch(actions.appCouponFetching());
    try {
      console.log({id},'adss');
      let res = await requestFromServer.getApplicationDetailCoupons(id,token)
      console.log(res.data);
      dispatch(actions.appCouponFetched({ appCoupon:res.data }));
    } catch (error) {
      dispatch(actions.errorappCoupon({ error:'Cant fetch applications coupons' }));    
    }
};

export const fetchAppAssignedCoupon = (id) => async dispatch => {
  dispatch(actions.assignedCouponFetching());
    try {
      console.log({id},'adss');
      let res = await requestFromServer.getAssignedCoupons(id)
      console.log(res.data);
      dispatch(actions.assignedCouponFetched({ appAssignedCoupon:res.data }));
    } catch (error) {
      dispatch(actions.errorassignedCoupon({ error:'Cant fetch applications assignedCoupons' }));    
    }
};

export const fetchAppAvailedCoupons = (id) => async dispatch => {
  dispatch(actions.assignedCouponFetching());
    try {
      console.log({id},'adss');
      let res = await requestFromServer.getAvailedCoupons(id)
      console.log(res.data);
      dispatch(actions.availedCouponFetched({ appAvailedCoupon:res.data }));
    } catch (error) {
      dispatch(actions.erroravailedCoupon({ error:'Cant fetch applications availedCoupons' }));    
    }
};


export const fetchAssignedNotAvailedCoupons = (id) => async dispatch => {
  dispatch(actions.assignedButNotAvailedCouponFetching());
    try {
      console.log({id},'adss');
      let res = await requestFromServer.getAssignedButNotAvailedCoupons(id)
      console.log(res.data);
      dispatch(actions.assignedButNotAvailedCouponFetched({ assignedButNotAvailedCoupon:res.data }));
    } catch (error) {
      dispatch(actions.errorAssignedButNotAvailedCoupon({ error:'Cant fetch applications availedCoupons' }));    
    }
};

export const fetchGraphData = (id,token) => async dispatch => {
  dispatch(actions.appGraphFetching());
    try {
      
      let res = await requestFromServer.getApplicationGraphData(id,token)
      console.log(res.data);
      dispatch(actions.appGraphFetched({ appGraphData:res.data }));
    } catch (error) {
      dispatch(actions.errorGraphCoupon({ error:'Cant fetch applications graphs' }));    
    }
};

export const fetchCouponGraph = (id,token) => async dispatch => {
  dispatch(actions.appCouponGraphFetching());
    try {
      
      let res = await requestFromServer.getCouponGraphData(id,token)
      console.log(res.data);
      dispatch(actions.appCouponGraphFetched({ appCouponData:res.data }));
    } catch (error) {
      dispatch(actions.errorCouponGraph({ error:'Cant fetch coupons graphs' }));    
    }
};

export const fetchAssignedCouponGraph = (id,token) => async dispatch => {
  dispatch(actions.assignedCouponGraphFetching());
    try {
      
      let res = await requestFromServer.getAssignedCouponGraph(id,token)
      console.log(res.data);
      dispatch(actions.assignedCouponGraphFetched({ assignedCouponGraph:res.data }));
    } catch (error) {
      dispatch(actions.errorAssignedCouponGraph({ error:'Cant fetch assignedcoupons graphs' }));    
    }
  };

    export const fetchAvailedCouponGraph = (id,token) => async dispatch => {
      dispatch(actions.availedCouponGraphFetching());
        try {
          
          let res = await requestFromServer.getAvailedCouponGraph(id,token)
          console.log(res.data);
          dispatch(actions.availedCouponGraphFetched({ availedCouponGraph:res.data }));
        } catch (error) {
          dispatch(actions.errorAvailedCouponGraph({ error:'Cant fetch availedcoupons graphs' }));    
        }
};

export const fetchNotAvailedCouponGraph = (id,token) => async dispatch => {
  dispatch(actions.notAvailedCouponGraphFetching());
    try {
      
      let res = await requestFromServer.getNotAvailedCouponGraph(id,token)
      console.log(res.data);
      dispatch(actions.notAvailedCouponGraphFetched({ notAvailedCouponGraph:res.data }));
    } catch (error) {
      dispatch(actions.errorNotAvailedCouponGraph({ error:'Cant fetch notAvailedcoupons graphs' }));    
    }
};
