import React from 'react';
import { Modal } from 'react-bootstrap';

const BookingModalHeader = (props) => {
    const {
        title,
    } = props;

    return (
        <Modal.Header closeButton>
            <Modal.Title id="booking-modal-sizes-title-lg">
                {title}
            </Modal.Title>
        </Modal.Header>
    )
}

export default React.memo(BookingModalHeader);