import * as auth from "../app/modules/Auth/_redux/authActions";
import { findOrganisationInfo } from "../app/pages/Organizations/_redux/organizationsCrud";
import { setupAxios } from ".";

export default async function initApp(store, axios) {
  //   const hostname =
  //     window.location.hostname +
  //     (window.location.port ? ":" + window.location.port : "");
  const { data } = await findOrganisationInfo();

  localStorage.organzationPath = `${window.location.protocol}//${data}`;
  setupAxios(axios, store);

  const {
    auth: { authToken, roles },
  } = store.getState();

  if (authToken) {
    auth.actions.login(authToken, roles);
    // store.dispatch(getProfile());
  } else {
    auth.actions.logout();
    // store.dispatch(logoutUser());
  }
}
