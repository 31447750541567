import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import CreateCouponForm from "./CreateCouponForm";
import { useParams } from "react-router-dom";

export default function CreateCouponPage() {
  const { appid } = useParams();
  return (
    <Card>
      <CardHeader title="Create Coupon">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CreateCouponForm appId={appid} />
      </CardBody>
    </Card>
  );
}
