import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import BookingTable  from "./booking-table/BookingTable";
import { useDispatch } from "react-redux";
//import * as actions from "./_redux/organizationsActions";


export function BookingCard() {
  //const organizationsUIContext = useOrganizationsUIContext();
  //const organizationsUIProps = useMemo(() => {
    //return {
      //ids: organizationsUIContext.ids,
      //newOrganizationButtonClick: organizationsUIContext.newOrganizationButtonClick,
    //};
  //}, [organizationsUIContext]);

  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.organizations }),
  //   shallowEqual
  // );
  // const { totalCount, entities, listLoading } = currentState;
  
  const dispatch = useDispatch();

  //useEffect(() => {
    //dispatch(actions.fetchOrganizations());
  //}, [dispatch])
  
  return (
    <Card>
      <CardHeader title="All Booking list">
        <CardHeaderToolbar>
       
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <BookingTable />
        
      </CardBody>
    </Card>
  );
}
