import React from "react";
import { Footer } from "../../../_metronic/layout/components/footer/Footer";

import BookingForm from "./components/BookingForm";

export default function BookingPage() {
  return (
    <div className="container">
      {/*<header className="d-flex justify-content-between">
            <div className="logo p-4">ICON HERE</div>
            <div className="image p-4">IMAGE HERE</div>
            <div className="logo p-4">ICON HERE</div>
    </header>*/}
      <div className="p-4">
        <BookingForm />
      </div>
    </div>
  );
}
