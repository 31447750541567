import React from "react";
import moment from 'moment';
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "./../../_metronic/_partials/controls/forms/customDatePickerWidth.css";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const KtDatePickerField = ({ ...props }) => {
  const { setFieldValue, errors, touched } = useFormikContext();
  const { name, handleChange, setInputValue } = props;
  const [field] = useField(props);
  return (
    <>
      {/* {props.label && <label>{props.label}</label>} */}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%", display: "block" }}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
          handleChange(name, val, setInputValue);
        }}
        minDate={new Date()}
        minTime={new Date(new Date().setTime(7200000))}
        maxTime={new Date(new Date().setTime(61200000))}
        maxDate={moment(new Date()).add(1, 'month').toDate()}
        showTimeSelect
        timeFormat="HH:mm:ss"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : null
        // (
        //   <div className="feedback">
        //     Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
        //   </div>
        // )
      }
    </>
  );
}

export default KtDatePickerField;