import React from "react";
import { Route } from "react-router-dom";
import { OrganizationsLoadingDialog } from "./organizations-loading-dialog/OrganizationsLoadingDialog";
import { OrganizationEditDialog } from "./organization-edit-dialog/OrganizationEditDialog";
import { OrganizationDeleteDialog } from "./organization-delete-dialog/OrganizationDeleteDialog";
import { OrganizationsDeleteDialog } from "./organizations-delete-dialog/OrganizationsDeleteDialog";
import { OrganizationsFetchDialog } from "./organizations-fetch-dialog/OrganizationsFetchDialog";
import { OrganizationsUpdateStateDialog } from "./organizations-update-status-dialog/OrganizationsUpdateStateDialog";
import { OrganizationsUIProvider } from "./OrganizationsUIContext";
import { OrganizationsCard } from "./OrganizationsCard";

export function OrganizationsPage({ history }) {
  const organizationsUIEvents = {
    newOrganizationButtonClick: () => {
      history.push("/organizations/new");
    },
    openEditOrganizationDialog: (id) => {
      history.push(`/organizations/${id}/edit`);
    },
    openDeleteOrganizationDialog: (id) => {
      history.push(`/organizations/${id}/delete`);
    },
    openDeleteOrganizationsDialog: () => {
      history.push(`/organizations/deleteOrganizations`);
    },
    openFetchOrganizationsDialog: () => {
      history.push(`/organizations/fetch`);
    },
    openUpdateOrganizationsStatusDialog: () => {
      history.push("/organizations/updateStatus");
    }
  }

  return (
    <OrganizationsUIProvider organizationsUIEvents={organizationsUIEvents}>
      <OrganizationsLoadingDialog />
      <Route path="/organizations/new">
        {({ history, match }) => (
          <OrganizationEditDialog
            show={match != null}
            onHide={() => {
              history.push("/organizations");
            }}
          />
        )}
      </Route>
      <Route path="/organizations/:id/edit">
        {({ history, match }) => (
          <OrganizationEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/organizations");
            }}
          />
        )}
      </Route>
      <Route path="/organizations/deleteOrganizations">
        {({ history, match }) => (
          <OrganizationsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/organizations");
            }}
          />
        )}
      </Route>
      <Route path="/organizations/:id/delete">
        {({ history, match }) => (
          <OrganizationDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/organizations");
            }}
          />
        )}
      </Route>
      <Route path="/organizations/fetch">
        {({ history, match }) => (
          <OrganizationsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/organizations");
            }}
          />
        )}
      </Route>
      <Route path="/organizations/updateStatus">
        {({ history, match }) => (
          <OrganizationsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/organizations");
            }}
          />
        )}
      </Route>
      <OrganizationsCard />
    </OrganizationsUIProvider>
  );
}
