import React, { useEffect, useState } from "react";
import { Modal, Paper } from "@material-ui/core";
import { Formik, Field } from "formik";
import { CircularProgress, Box, Typography, Card } from "@material-ui/core";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Input } from "../../../../_metronic/_partials/controls";
import { Offer, fetchOfferData } from "../redux/offerAction";
import __env from "../../../../env";
import { useHistory } from "react-router-dom";
import "../../../../styles/landingPage.scss";

toast.configure();

const OfferForm = ({ ...props }) => {
  const history = useHistory();
  const { intl } = props;
  const { appid, couponCode } = useParams();
  const [bookingModal, setBookingModal] = useState(false);
  console.log("appid", appid);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const OpenTime = props.getOffer ? props.getOffer.app.openTime : "";
  const CloseTime = props.getOffer ? props.getOffer.app.closeTime : "";

  const openTime = new Date(OpenTime).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    timeZone: "Asia/Kolkata",
  });
  const closeTime = new Date(CloseTime).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    timeZone: "Asia/Kolkata",
  });

  const date = new Date();
  const formatedDate = (value) => {
    let date = new Date(value);
    return (
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2)
    );
  };
  const handleOffer = (values) => {
    const startDateStr = formatedDate(values.startTime);
    const endDateStr = formatedDate(values.endTime);

    if (startDateStr > endDateStr) {
      alert("End date need to be bigger then start date");
    }
    const formData = {
      ...values,
      startTime: startDateStr,
      endTime: endDateStr,
      appId: appid,
      couponCode: couponCode,
    };
    if (formData) {
      setLoading(true);
      props
        .Offer(formData)
        .then((response) => {
          toast.success("Offer created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
          setLoading(false);
        });
    }
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const numberRegExp = /^\d+$/;

  const renderS3Content = ({ ...props }) => {
    let landingURL = document.getElementById("hLandingPageUrl").value;
    // alert(landingURL);
    // if (appid == "efcf7d4a-c665-4264-96ba-fc00a74dd610") {
    //   landingURL = "http://localhost:3000/media/templates/s3/01/index.html";
    // } else {
    //   landingURL = "http://localhost:3000/media/templates/s3/01/index.html";
    // }
    landingURL = "http://localhost:3000/media/templates/s3/01/index.html";
    fetch(landingURL)
      .then(function(response) {
        return response.text();
      })
      .then(function(string) {
        document.getElementsByTagName("body")[0].style.backgroundColor =
          "black";
        //const logoUrl = props?.getOffer?.user?.retailer?.retailerImage;
        document.getElementById("s3root").innerHTML = string
          .replace(/{{Your Name}}/g, "Sunil Bansal")
          .replace(
            /{{logo}}/g,
            "<img src='" +
              document.getElementById("hLogo").value +
              "' style='display: block; height: auto; border: 0; width: 60px; max-width: 100%;' />"
          )
          .replace(/{{tagline}}/g, document.getElementById("hTagLine").value)
          .replace(
            /{{description}}/g,
            document.getElementById("hDescription").value
          )
          .replace(
            /{{retailerImage}}/g,
            document.getElementById("hRetailerImage").value
          )
          .replace(/{{timings}}/g, document.getElementById("hTimings").value)
          .replace(
            /{{coupon-assign-form}}/g,
            `<form novalidate>
              <p>
                <label for="txtName">
                  <input type="text" id="txtName" name="txtName" required autocomplete="off" placeholder="Enter your name" />
                  <span class="error" aria-live="polite"></span>
                </label>
              </p>
              <p>
                <label for="txtEmail">
                  <input type="email" id="txtEmail" name="txtEmail" required autocomplete="off" placeholder="Enter your email" />
                  <span class="error" aria-live="polite"></span>
                </label>
              </p>
              <p>
                <label for="phone">
                  <input
                    type="text"
                    id="txtPhone"
                    name="txtPhone"
                    required
                    pattern="[0-9]{10}"
                    autocomplete="off"
                    placeholder="Enter your phone"
                  />
                  <span class="error" aria-live="polite"></span>
                </label>
              </p>
              <button id="btnSubmit">Claim Your Coupon</button>
            </form>`
          );
        //"<input id='btnSubmit' type='button' value='Claim Your Coupon' /><label id='lblErrorSubmit' />"
        // var contactForm = document.getElementById("contact-form");
        // if (contactForm.checkValidity() == false) {
        //   var list = contactForm.querySelectorAll(":invalid");
        //   for (var item of list) {
        //     item.setAttribute("style", "background-color: red;");
        //   }
        // }
        const onCouponSubmit = (event) => {
          //event.preventDefault();
          debugger;
          document.getElementById("btnSubmit").disabled = true;
          document.getElementById("btnSubmit").style.backgroundColor = "gray";
          //Get Template Form Data
          let formName = document.getElementById("txtName").value;
          let formEmail = document.getElementById("txtEmail").value;
          let formPhone = document.getElementById("txtPhone").value;
          //Validate Data
          let formValidation = false;
          document.getElementsByClassName("validateField").forEach((field) => {
            if (
              document.getElementById(field.getAttribute("data-validate"))
                .value == ""
            ) {
              formValidation = false;
              console.log(`${field.id} not filled.`);
              field.innerHTML = "Required field";
            }
          });
          if (formValidation) {
            var formdata = new FormData();
            formdata.append("name", formName);
            formdata.append("mobile", formPhone);
            formdata.append("appId", appid);
            formdata.append("couponCode", couponCode);
            formdata.append("email", formEmail);

            var requestOptions = {
              method: "POST",
              body: formdata,
              redirect: "follow",
            };
            fetch(`${__env.dataApi}/couponCode/assignCode`, requestOptions)
              .then((response) => response.text())
              .then((result) => {
                // toast.success(JSON.parse(result).message, {
                //   position: toast.POSITION.BOTTOM_RIGHT,
                //   autoClose: 1000,
                // });
                document.getElementById("txtName").value = "";
                document.getElementById("txtEmail").value = "";
                document.getElementById("txtPhone").value = "";
                setBookingModal(true);
              })
              .catch((error) => alert("error", error));
          }
        };
        // document
        //   .getElementById("btnSubmit")
        //   .addEventListener("click", onCouponSubmit);

        const form = document.getElementsByTagName("form")[0];

        const name = document.getElementById("txtName");
        const nameError = document.querySelector("#txtName + span.error");

        name.addEventListener("input", function(event) {
          if (name.validity.valid) {
            nameError.innerHTML = ""; // Reset the content of the message
            nameError.className = "error"; // Reset the visual state of the message
          } else {
            // If there is still an error, show the correct error
            showError("name");
          }
        });

        const phone = document.getElementById("txtPhone");
        const phoneError = document.querySelector("#txtPhone + span.error");

        phone.addEventListener("input", function(event) {
          if (phone.validity.valid) {
            phoneError.innerHTML = ""; // Reset the content of the message
            phoneError.className = "error"; // Reset the visual state of the message
          } else {
            // If there is still an error, show the correct error
            showError("phone");
          }
        });

        const email = document.getElementById("txtEmail");
        const emailError = document.querySelector("#txtEmail + span.error");

        email.addEventListener("input", function(event) {
          if (email.validity.valid) {
            emailError.innerHTML = ""; // Reset the content of the message
            emailError.className = "error"; // Reset the visual state of the message
          } else {
            // If there is still an error, show the correct error
            showError("email");
          }
        });

        form.addEventListener("submit", function(event) {
          if (!name.validity.valid) {
            showError("name");
            event.preventDefault();
          }
          if (!phone.validity.valid) {
            showError("phone");
            event.preventDefault();
          }
          if (!email.validity.valid) {
            showError("email");
            event.preventDefault();
          }
          if (
            name.validity.valid &&
            phone.validity.valid &&
            email.validity.valid
          ) {
            event.preventDefault();
            submitCouponForm();
          }
        });
        function submitCouponForm() {
          document.getElementById("btnSubmit").disabled = true;
          document.getElementById("btnSubmit").style.backgroundColor = "gray";
          //Get Template Form Data
          let formName = document.getElementById("txtName").value;
          let formEmail = document.getElementById("txtEmail").value;
          let formPhone = document.getElementById("txtPhone").value;

          var formdata = new FormData();
          formdata.append("name", formName);
          formdata.append("mobile", formPhone);
          formdata.append("appId", appid);
          formdata.append("couponCode", couponCode);
          formdata.append("email", formEmail);

          var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };
          fetch(`${__env.dataApi}/couponCode/assignCode`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              // toast.success(JSON.parse(result).message, {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   autoClose: 1000,
              // });
              document.getElementById("txtName").value = "";
              document.getElementById("txtEmail").value = "";
              document.getElementById("txtPhone").value = "";
              setBookingModal(true);

              if (
                JSON.parse(result).assignCoupon &&
                JSON.parse(result).assignCoupon != null &&
                JSON.parse(result).assignCoupon.id.length > 0
              ) {
                console.log(JSON.parse(result).assignCoupon);
              } else {
                document.getElementById("msgThanks").innerHTML = JSON.parse(
                  result
                ).message;
              }
              document.getElementById("btnSubmit").disabled = false;
              document.getElementById("btnSubmit").style.backgroundColor =
                "#41d873";
            })
            .catch((error) => alert("error", error));
        }
        function showError(element) {
          if (element == "name") {
            if (name.validity.valueMissing) {
              nameError.textContent = "Please enter your name.";
            }
            nameError.className = "error active";
          }
          if (element == "phone") {
            if (phone.validity.valueMissing) {
              phoneError.textContent = "Please enter your phone.";
            } else if (phone.validity.typeMismatch) {
              phoneError.textContent = "Please enter a valid phone.";
            }
            phoneError.className = "error active";
          }
          if (element == "email") {
            if (email.validity.valueMissing) {
              emailError.textContent = "Please enter your e-mail.";
            } else if (email.validity.typeMismatch) {
              emailError.textContent = "Please enter a valid e-mail.";
            }
            // Set the styling appropriately
            emailError.className = "error active";
          }
        }
      });
  };

  useEffect(() => {
    props.fetchOfferData(appid);
    console.log("dattata", props);
    setTimeout(() => {
      renderS3Content();
    }, 1000);
  }, []);

  return (
    <div>
      {!success ? (
        <div>
          <input
            id="hLogo"
            type="hidden"
            value={props?.getOffer?.user?.retailer?.logo}
          />
          <input
            id="hTagLine"
            type="hidden"
            value={props?.getOffer?.app?.tagLine}
          />
          <input
            id="hDescription"
            type="hidden"
            value={props?.getOffer?.app?.description}
          />
          <input
            id="hLandingPageUrl"
            type="hidden"
            value={props?.getOffer?.app?.landingPageUrl}
          />
          <input
            id="hRetailerImage"
            type="hidden"
            value={props?.getOffer?.user?.retailer?.retailerImage}
          />
          <input
            id="hTimings"
            type="hidden"
            value={`From ${openTime} to ${closeTime}`}
          />
          <div id="s3root"></div>
          <Modal
            className="d-flex justify-content-center align-items-center w-300 h-300"
            open={bookingModal}
            onClose={() => setBookingModal(false)}
          >
            <Paper>
              <div
                style={{
                  width: "400px",
                  margin: "30px auto",
                  textAlign: "center",
                }}
              >
                <div
                  style={{ margin: "10px auto 40px auto", color: "#4caf50" }}
                >
                  <h3>SUCCESS - Coupon Assigned!</h3>
                </div>
                <div id="msgThanks">Thank you for claiming your coupon!</div>
                <div style={{ display: "none" }}>
                  <h4 style={{ margin: "5px auto" }}>
                    Want to book your slot?
                  </h4>
                </div>
                <button
                  style={{
                    backgroundColor: "#4caf50",
                    color: "#ffffff",
                    width: "75%",
                    border: "solid 1px #464646",
                    height: "30px",
                    margin: "20px auto 0px auto",
                    display: "none",
                  }}
                  onClick={() => {
                    history.push(`/page/booking/${appid}/${couponCode}`);
                  }}
                >
                  Book Now
                </button>
              </div>
              <h3
                style={{
                  height: "30px",
                  padding: "10px 20px",
                  width: "90%",
                  fontSize: "10px",
                }}
              ></h3>
            </Paper>
          </Modal>
        </div>
      ) : (
        <div className="text-center mt-2 mb-lg-2" style="width: 18rem;">
          <div className="card-header">
            <h2 className="text-dark font-weight-bold">Thank you for Offer</h2>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.offers.error,
  success: state.offers.success,
  loading: state.offers.loading,
  offer: state.offers.offer,
  getOffer: state.offers.fetchOffer,
});

const mapActionsToProps = {
  Offer,
  fetchOfferData,
};
//export default connect(mapStateToProps, mapActionsToProps)(OfferForm);
export default injectIntl(
  connect(mapStateToProps, mapActionsToProps)(OfferForm)
);
