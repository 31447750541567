import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import CouponTable from "./coupon-table/CouponTable";
import { useDispatch } from "react-redux";
//import * as actions from "./_redux/organizationsActions";

export function CouponCard() {
  //const organizationsUIContext = useOrganizationsUIContext();
  //const organizationsUIProps = useMemo(() => {
  //return {
  //ids: organizationsUIContext.ids,
  //newOrganizationButtonClick: organizationsUIContext.newOrganizationButtonClick,
  //};
  //}, [organizationsUIContext]);

  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.organizations }),
  //   shallowEqual
  // );
  // const { totalCount, entities, listLoading } = currentState;

  //useEffect(() => {
  //dispatch(actions.fetchOrganizations());
  //}, [dispatch])

  return (
    <Card>
      <CardHeader title="All Coupons list">
        <CardHeaderToolbar>
        {/*
          <Link to="/create-coupon/1cfcfcb9-9cf7-462e-9e6c-73e218ed17c7">
            <button
              type="button"
              className="btn btn-primary ml-2"
              data-toggle="modal"
              data-target="#exampleModalLong"
            >
              <i className="ki ki-menu icon-md mr-2"></i>
              Create Coupon
            </button>
  </Link>*/}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CouponTable />
      </CardBody>
    </Card>
  );
}
