import React from "react";
import CouponForm from "./components/CouponForm";
import { useParams } from "react-router-dom";

export default function CouponPage(props) {
  const { appid, couponcode } = useParams();
  return (
    <div className="container">
      {/*<header className="d-flex justify-content-between">
                <div className="logo p-2">
                 <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpaMsGKdtkPhsCSRZTFvSX5q2dSP6kbBfC3A&usqp=CAU" width="80" height="80"  alt="" className="d-inline-block align-middle mr-2"/>
                 </div>
                <div className="image p-2"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpaMsGKdtkPhsCSRZTFvSX5q2dSP6kbBfC3A&usqp=CAU" width="80"  height="80" /></div>
                <div className="logo p-2"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpaMsGKdtkPhsCSRZTFvSX5q2dSP6kbBfC3A&usqp=CAU" width="80"  height="80" /></div>
    </header>*/}

      <div className="p-2">
        <CouponForm appId={appid} couponCode={couponcode} className="test" />
      </div>
    </div>
  );
}
