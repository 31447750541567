import React, { useRef, useState } from "react";
import { Modal, Paper } from "@material-ui/core";
import { QrReader } from "react-qr-reader";
import { ScanCoupon } from "../../pages/Coupon/redux/couponAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

// KONWN ISSUES
// Due to browser implementations the camera can only be accessed over https or localhost.
// In Firefox a prompt will be shown to the user asking which camera to use, so facingMode will not affect it.
// On IOS 11 it is only supported on Safari and not on Chrome or Firefox due to Apple making the API not available to 3th party browsers.

const CouponScanner = ({ auth, ...props }) => {
  console.log("PROPS: " + JSON.stringify(props));
  const [qrReaderModal, setqrReaderModal] = useState(false);
  const [qrScanResultModal, setqrScanResultModal] = useState(false);
  const token = auth ? auth.authToken : null;
  // QR Code Scanner
  const onScanFile = () => {
    console.log("open");
    setqrReaderModal(true);
  };
  const qrRef = useRef(null);
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const handleErrorWebCam = (error) => {
    //alert(JSON.stringify(error));
    console.log("CAM ERROR");
  };
  const handleScanWebCam = (result) => {
    if (result && typeof result != "undefined") {
      console.log(JSON.stringify(result.text));
      handleScannedCoupon(JSON.parse(result.text));
    }
  }; // End of QR Code Scanner

  //Coupon Scanned Data push to API
  const handleScannedCoupon = (scannedData) => {
    if (token) {
      try {
        props
          .ScanCoupon(token, scannedData)
          .then((response) => {
            console.log(JSON.stringify(response));
            setScanResultWebCam(JSON.stringify(response.message));
            // toast.success("Coupon availed successfully", {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            //   autoClose: 2000,
            // });
            alert("going to close modal");
            setqrReaderModal(false);
            setqrScanResultModal(true);
          })
          .catch((err) => {
            console.log(err.response);

            const errorDetails = err?.response?.data?.message
              ? err.response.data.message
              : "Something went wrong";
            setScanResultWebCam(errorDetails);
            // toast.error(errorDetails, {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            //   autoClose: 2000,
            // });
            setqrReaderModal(false);
            setqrScanResultModal(true);
          });
      } catch (error) {
        alert("Error: " + JSON.stringify(error));
      }
    }
  };
  //Ends here

  return (
    <>
      <button
        type="button"
        className="btn btn-primary ml-2"
        data-toggle="modal"
        data-target="#exampleModalLong"
        onClick={onScanFile}
      >
        <i className="ki ki-menu icon-md mr-2"></i>
        Scan Coupon
      </button>

      <Modal
        className="d-flex justify-content-center align-items-center w-300 h-300"
        open={qrReaderModal}
        onClose={() => setqrReaderModal(false)}
      >
        <Paper>
          <div style={{ width: "400px" }}>
            <div className="text-primary font-weight-bold font-size-h2 ml-3 mt-2 d-inline text-center w-100">
              Scan Coupon QR Code
            </div>
            <div
              style={{
                boxSizing: "border-box",
                zIndex: "1600",
                border: "20px solid rgba(0, 0, 0, 0.3)",
                boxShadow: "rgb(255 0 0 / 50%) 0px 0px 0px 5px inset",
                width: "400px !important",
                height: "70%",
              }}
            >
              <QrReader
                delay={2000}
                delayBetweenScanSuccess={2000}
                delayBetweenScanAttempts={2000}
                onError={handleErrorWebCam}
                onScan={handleScanWebCam}
                onResult={handleScanWebCam}
              />
            </div>
          </div>
        </Paper>
      </Modal>
      <Modal
        className="d-flex justify-content-center align-items-center w-300 h-300"
        open={qrScanResultModal}
        onClose={() => setqrScanResultModal(false)}
      >
        <Paper>
          <div style={{ width: "400px", textAlign: "center", padding: "20px" }}>
            <div className="text-primary font-weight-bold font-size-h2 ml-3 mt-2 d-inline text-center w-100">
              Scan Result
            </div>

            <h3
              style={{
                minHeight: "100px",
                padding: "10px 20px",
                width: "90%",
                fontSize: "12px",
                margin: "auto",
              }}
            >
              {scanResultWebCam}
            </h3>
            <br />
            <button
              type="button"
              className="btn btn-primary ml-2"
              data-toggle="modal"
              data-target="#exampleModalLong"
              onClick={onScanFile}
            >
              <i className="ki ki-menu icon-md mr-2"></i>
              Scan Coupon
            </button>
          </div>
        </Paper>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  availCoupon: state.AvailedCoupon,
  auth: state.auth,
});

const mapActionsToProps = {
  ScanCoupon,
};

export default connect(mapStateToProps, mapActionsToProps)(CouponScanner);
