import { actionTypes } from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {
  axios.defaults.baseURL = localStorage.organzationPath;

  axios.interceptors.request.use(
    (config) => {
      const {
        // auth
        auth: { authToken, user },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
        if (user) {
          config.headers["Organization"] = user.organization?.id;
        }
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response && 401 === error.response.status) {
        store.dispatch({ type: actionTypes.Logout });
      }
      return Promise.reject({ ...error });
    }
  );
}
