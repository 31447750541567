import * as requestFromServer from "./organizationsCrud";
import { organizationsSlice, callTypes } from "./organizationsSlice";
import { getErrorMessage } from "../../../utils";

const { actions } = organizationsSlice;

export const fetchOrganizations = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrganizations()
    .then(response => {
      // const { totalCount, entities } = response.data;
      const totalCount = response.data.length;
      const entities = response.data;
      dispatch(actions.organizationsFetched({ entities, totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find organizations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrganization = id => dispatch => {
  if (!id) {
    return dispatch(actions.organizationFetched({ organizationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrganizationById(id)
    .then(response => {
      const organization = response.data;
      dispatch(actions.organizationFetched({ organizationForEdit: organization }));
    })
    .catch(error => {
      error.clientMessage = "Can't find organization";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrganization = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrganization(id)
    .then(response => {
      dispatch(actions.organizationDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete organization";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrganization = organizationForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrganization(organizationForCreation)
    .then(response => {
      const organization = response.data;
      dispatch(actions.organizationCreated({ organization }));
    })
    .catch(error => {
      error.clientMessage = "Can't create organization";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrganization = (id, organization) => dispatch => {
  return new Promise(function (resolve, reject) {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .updateOrganization(id, organization)
      .then(() => {
        dispatch(actions.organizationUpdated({ organization }));
        resolve();
      })
      .catch(error => {
        // error.clientMessage = "Can't update organization";
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const updateOrganizationsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForOrganizations(ids, status)
    .then(() => {
      dispatch(actions.organizationsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update organizations status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrganizations = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrganizations(ids)
    .then(() => {
      dispatch(actions.organizationsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete organizations";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
