// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
//import { shallowEqual, useSelector } from "react-redux";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import {connect} from 'react-redux';
import {useSelector} from 'react-redux';
import { fetchTotalBooking } from '../redux/bookingAction';
//import * as uiHelpers from "../ApplicationsUIHelpers";
//import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import {Redirect} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
//import { useApplicationsUIContext } from "../ApplicationsUIContext";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import SVG from "react-inlinesvg";
import { TextField, Grid } from '@material-ui/core';
import { ToastContainer, toast, Slide } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
/* ========= style for form ========= */
import "../../../../styles/applicationTable.scss"

const BookingTable=({auth,...props}) =>{
 const history=useHistory();
 

  // Applications UI Context
  //const applicationsUIContext = useApplicationsUIContext();
  //const applicationsUIProps = useMemo(() => {
  //return {
  //ids: applicationsUIContext.ids,
  //setIds: applicationsUIContext.setIds,
  //queryParams: applicationsUIContext.queryParams,
  //setQueryParams: applicationsUIContext.setQueryParams,
  //openEditApplicationDialog: applicationsUIContext.openEditApplicationDialog,
  //openDeleteApplicationDialog: applicationsUIContext.openDeleteApplicationDialog,
  //};
  //}, [applicationsUIContext]);

  // const entities = [
  //   { id: '0', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '223456789', mobile: '9898989899' ,numberOfPersons:2,bookingStartTime:"2022-04-11 10:51:00",bookingEndTime: "2022-04-12 10:46:00"},
  //   { id: '1', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '323456789', mobile: '9898989899' ,numberOfPersons:3,bookingStartTime:"2022-04-11 10:51:00",bookingEndTime: "2022-04-12 10:46:00"},
  //   { id: '2', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '423456789', mobile: '9898989899',numberOfPersons:3,bookingStartTime:"2022-04-11 10:51:00" ,bookingEndTime: "2022-04-12 10:46:00"},
  //   { id: '3', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '523456789', mobile: '9898989899' ,numberOfPersons:3 ,bookingStartTime:"2022-04-11 10:51:00",bookingEndTime: "2022-04-12 10:46:00"},
  //   { id: '4', name: 'kabkjbsa', email: 'asjdnj@kn.ascas', appid: '623456789', mobile: '9898989899' ,numberOfPersons:3,bookingStartTime:"2022-04-11 10:51:00",bookingEndTime: "2022-04-12 10:46:00" },
  // ];
  //  console.log("dataysadsd",props.bookings);
  
  const selectOptionsArr = [{
    value: false,
    label: 'Active'
  }, {
    value: true,
    label: 'InActive'
  }];
  

  useEffect(()=>{
    if(auth){
   props.fetchTotalBooking(auth.authToken);
    }
  },[auth])
  console.log("propssss",props)
   console.log("total Booking", props.bookings)
 
  // const handleClick = (id) => {
  //   navigator.clipboard
  //     .writeText(id)
  //     .then(() => {
  //       toast.success('Copied!', {
  //         position: "top-center",
  //         autoClose: 1000,
  //         hideProgressBar: true,
  //         pauseOnHover: true,
  //         progress: undefined,
  //       });
  //     })
  //     .catch(() => {
  //       toast.error('Something went wrong!', {
  //         position: "top-center",
  //         autoClose: 1000,
  //         hideProgressBar: true,
  //         pauseOnHover: true,
  //         progress: undefined,
  //       });
  //     });
  // }
  // Getting curret state of applications list from store (Redux)
  //const { currentState } = useSelector(
  //(state) => ({ currentState: state.applications }),
  //shallowEqual
  //);
  //const { totalCount, entities, listLoading } = currentState;

  // Applications Redux state
  //useEffect(() => {
  // clear selections list
  //applicationsUIProps.setIds([]);
  // server call by queryParams
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [applicationsUIProps.queryParams, entities]);
  // Table columns
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   accessor: "link",
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
      
    // },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    // {
    //   dataField: "appid",
    //   text: "AppID",
    //   formatter: (cell,row) => {
    //     return (
    //       <Grid container={true} style={{width:"120%"}}>
    //         <TextField
    //               disabled
    //               id="filled-disabled"
    //               defaultValue={row.appid}
    //               variant="filled"
    //               type="password"
    //               style={{borderTopRightRadius: "0px"}}
    //         />
    //         <div className="symbol symbol-50 symbol-light mr-1">
    //             <span 
    //             className="symbol-label" 
    //             style={{
    //                 backgroundColor:"#f5f7fa",
    //                 borderRadius:"0",
    //                 height: "39.44px",
    //                 borderTopRightRadius: "4px",
    //                 cursor: "pointer",
    //             }}
    //             onClick={() => handleClick(row.appid)}
    //             >
    //                 <img
    //                     src={toAbsoluteUrl("/media/svg/icons/Media/copy.png")}
    //                     className="h-50 align-self-center"
    //                 />
    //                 <ToastContainer position="top-center"
    //                   autoClose={1000}
    //                   hideProgressBar={true}
    //                   newestOnTop={false}
    //                   transition={Slide}
    //                   rtl={false}
    //                   pauseOnFocusLoss
    //                   pauseOnHover
    //                 />
    //             </span>
    //         </div>
    //       </Grid>
    //     );
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "120%",
    //   },
    // },
    {
      dataField: "mobile",
      text: "Mobile",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },

    {
      dataField: "numberOfPersons",
      text: "Persons",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },

    {
      dataField: "visited",
      text: "Visited",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    {
      dataField: "meetingStartTime",
      text: "StartTime",
      sort: true,
     // sortCaret: sortCaret,
     // headerSortingClasses,
      filter: textFilter(),
      formatter: (cell) => {
        return(
          <>
          {moment(cell).format("LL")}
          <br/>
          {moment(cell).format("hh:mm A")}</>
        )
      },
    },
    {
      dataField: "meetingEndTime",
      text: "EndTime",
      sort: true,
     // sortCaret: sortCaret,
      //   headerSortingClasses,
      filter: textFilter(),
      formatter: (cell) => {
        return(
          <>
          {moment(cell).format("LL")}
          <br/>
          {moment(cell).format("hh:mm A")}</>
        )
      },
    },
    
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    //totalSize: totalCount,
    totalSize: '0',
    //sizePerPageList: uiHelpers.sizePerPageList,
    //sizePerPage: applicationsUIProps.queryParams.pageSize,
    //page: applicationsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              //isLoading={listLoading}
              paginationProps={paginationProps}
            >
            
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-align-top-left cursor"
                bootstrap4
                hover={true}
              keyField="id"
                data={props.bookings === null ? [] : props.bookings}
                columns={columns}
                //defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  //applicationsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: applicationsUIProps.ids,
                //   setIds: applicationsUIProps.setIds,
                // })}
                filter={filterFactory()}
                {...paginationTableProps}
              >
                {/*<PleaseWaitMessage entities={entities} />*/}
                {/*<NoRecordsFoundMessage entities={entities} />*/}
              </BootstrapTable>

            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.bookings.error,
  success: state.bookings.success,
  loading: state.bookings.loading,
  bookings: state.bookings.allBookings,
  auth:state.auth
})

const mapActionsToProps = {

  fetchTotalBooking

};
export default connect(mapStateToProps, mapActionsToProps)(BookingTable);
