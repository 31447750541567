import React, { useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import {format} from 'date-fns';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { fetchGraphData } from '../../pages/Applications/_redux/organizationsActions';



const BookingChart = ({ auth, ...props }) => {
  const { id } = useParams();
  console.log('appid', id)
   const dateFormatter =(dates) => {
    return moment(dates).format("MMM D");
  };
  
  // static demoUrl = "https://codesandbox.io/s/simple-area-chart-4ujxw";
  // const date = moment(dates).format(' LL')
  useEffect(() => {
    if (auth) {
      props.fetchGraphData(id,auth.authToken)
      console.log("graphdata", props.appGraphData)
    }
  }, [])
  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        width={500}
        height={400}
        data={props.appGraphData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dates" 
        tickFormatter={dateFormatter}
       />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="count" stroke="#1d738b" fill="#1d738b" />
      </AreaChart>
    </ResponsiveContainer>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  appGraphData: state.applications.appGraphData,
  auth: state.auth

})

const mapActionsToProps = {
  fetchGraphData

};
export default connect(mapStateToProps, mapActionsToProps)(BookingChart);
