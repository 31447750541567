import * as requestFromServer from "./offerCrud";
import { offerSlice } from "./offerSlice";
import { getErrorMessage } from "../../../utils";

const { actions } = offerSlice;

export const Offer = (data) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .CreateOffer(data)
    .then((response) => {
      const offerResponse = response.data;
      dispatch(actions.offerData({ offerResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const fetchTotalOffer = (token) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .getALLOffers(token)
    .then((response) => {
      const offerResponse = response.data;
      console.log("totallBokinggggg", offerResponse);
      dispatch(actions.totalOffers({ offerResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const fetchOfferData = (appid) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .fetchOffers(appid)
    .then((response) => {
      const offerResponse = response.data;
      console.log("offerResponsne4", offerResponse);
      dispatch(actions.offerFetched({ offerResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};
