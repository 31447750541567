/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { useLocation } from "react-router";
// import { NavLink } from "react-router-dom";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux";

export function HeaderMenu({ layoutProps }) {
    // const location = useLocation();
    // const getMenuItemActive = (url) => {
    //     return checkIsActive(location, url) ? "menu-item-active" : "";
    // }
    const { user } = useSelector(
        ({ auth }) => ({
            user: auth.user,
        }),
        shallowEqual
    );

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        {(user && user.organization) && (<h4 style={{color:"white",textAlign:"center",alignSelf:"center"}}>{user.organization.name}</h4>)}
        {/*end::Header Nav*/}
    </div>;
}
