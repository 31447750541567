import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import { actionTypes } from "./authRedux";
import * as auth from "./authActions";

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(auth.actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(auth.actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const { data: user } = yield getUserByToken();

        yield put(auth.actions.fulfillUser(user));
    });
}
