import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import { shallowEqual, useSelector } from "react-redux";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import { connect } from "react-redux";
import { FetchAllRetailerApps } from "../redux/appAction";
//import * as uiHelpers from "../ApplicationsUIHelpers";
//import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
//import { useApplicationsUIContext } from "../ApplicationsUIContext";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import SVG from "react-inlinesvg";
import { TextField, Grid } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* ========= style for form ========= */
import "../../../../styles/applicationTable.scss";

function AppListTable({ auth, ...props }) {
  const { id } = useParams();
  console.log("id", id);

  useEffect(() => {
    
      props.FetchAllRetailerApps(id);
   
    console.log("total apps1", props.retailerApps);
  }, []);

  useEffect(() => {
    if (props.retailerApps) {
      console.log("total apps2", props.retailerApps.apps);
    }
  }, [props.retailerApps]);

  const selectOptionsArr = [
    {
      value: false,
      label: "Active",
    },
    {
      value: true,
      label: "InActive",
    },
  ];

  const handleClick = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success("Copied!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: undefined,
        });
      });
  };

  


  const customHeaderTemplate = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: "20px" }}>
          <span>
            {column.text}{sortElement}
          </span>
        </div>
        <div>{filterElement}</div>
      </div>
    );
  };

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   accessor: "link",
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "appName",
      text: "AppName",
      sort: true,
      accessor: "link",
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "tagLine",
      text: "TagLine",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "appId",
      text: "AppID",
      formatter: (cell, row) => {
        return (
          <Grid container={true} style={{ width: "100%" }}>
          <div style={{ display: "flex", width: "100px" }}>
            <TextField
              disabled
              id="filled-disabled"
              defaultValue={row.appId}
              variant="filled"
              type="password"
              style={{ borderTopRightRadius: "0px", width: "60%" }}
            />
            <div className="symbol symbol-50 symbol-light mr-1">
              <span
                className="symbol-label"
                style={{
                  backgroundColor: "#f5f7fa",
                  borderRadius: "0",
                  height: "39.44px",
                  borderTopRightRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleClick(row.appId)}
              >
                <img
                  src={toAbsoluteUrl("/media/svg/icons/Media/copy.png")}
                  className="h-50 align-self-center"
                />
                <ToastContainer
                  position="top-center"
                  autoClose={1000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  transition={Slide}
                  rtl={false}
                  pauseOnFocusLoss
                  pauseOnHover
                />
              </span>
            </div>
            </div>
          </Grid>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120%",
      },
    },
  ];
  // Table pagination properties
  const PaginationOptions={
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
  
    }
  };

  return (
    <>
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              paginationProps={paginationProps}
            > */}
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-align-top-left cursor"
        bootstrap4
        keyField="id"
        hover={true}
        data={props.retailerApps.apps || []}
        columns={columns}
        // rowEvents={tableRowEvents}
        onTableChange={getHandlerTableChange()}
        filter={filterFactory()}
        // {...paginationTableProps}
      ></BootstrapTable>
      {/* </Pagination>
          );
        }}
      </PaginationProvider> */}
    </>
  );

}
const mapStateToProps = (state) => ({
  error: state.apps.error,
  success: state.apps.success,
  loading: state.apps.loading,
  retailerApps: state.apps.retailerApps,
  auth: state.auth,
});

const mapActionsToProps = {
  FetchAllRetailerApps,
};
export default connect(mapStateToProps, mapActionsToProps)(AppListTable);
