import React, { Component } from "react";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Input } from "../../../../_metronic/_partials/controls";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authActions";
import { requestPassword } from "../_redux/authCrud";
import { getInputClasses } from "../../../utils/formUtil";

// TODO: Rewrite to pure function
class ResetPassword extends Component {
    state = { isRequested: false, message: '' };

    render() {
        const { intl } = this.props;
        const { isRequested, message } = this.state;

        if (isRequested) {
            return <Redirect to="/auth" />;
        }       

        return (
            <div className="login-form">
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">Reset Password</h3>
                </div>

                <Formik
                    initialValues={{ mobile: "" }}
                    validate={values => {
                        const errors = {};

                        if (!values.mobile) {
                            errors.mobile = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (
                            // !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mobile)
                            !/^(6|7|8|9)\d{9}$/i.test(values.mobile)
                        ) {
                            errors.mobile = intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        // console.log("values", values);
                        requestPassword(values.mobile)
                            .then(({data}) => {
                                this.setState({ isRequested: true, message: data });
                            })
                            .catch(() => {
                                setSubmitting(false);
                                setStatus(
                                    intl.formatMessage(
                                        { id: "AUTH.VALIDATION.NOT_FOUND" },
                                        { name: values.mobile }
                                    )
                                );
                            });
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                            <form onSubmit={handleSubmit} className="form">
                                {status && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                )}

                                {message && (
                                    <div
                                        role="alert"
                                        className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                                    >
                                        <div className="alert-text font-weight-bold">{message}</div>
                                    </div>
                                )}

                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="mobile"
                                        component={Input}
                                        placeholder="mobile"
                                        label="mobile"
                                    >

                                        {({ field, form, meta }) => (
                                            <div>
                                                <input
                                                    type="text" {...field}
                                                    className={`${getInputClasses(meta)}`}
                                                    placeholder="Enter mobile"
                                                    maxLength="10"
                                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }} />
                                                {meta.touched &&
                                                    meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div className="form-group d-flex flex-wrap flex-center">
                                    <button
                                        id="kt_login_forgot_submit"
                                        type="submit"
                                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                </button>
                                    <Link to="/auth">
                                        <button
                                            type="button"
                                            id="kt_login_forgot_cancel"
                                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            Cancel
                                    </button>
                                    </Link>
                                </div>
                            </form>
                        )}
                </Formik>
            </div>
        );
    }
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
