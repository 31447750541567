import axios from "axios";
import __env from "../../../../env";

export function createApp(token,category,page,data) {
    console.log("catergorypage",token,category,page,data)

    return axios.post(`${__env.dataApi}/apps/${category}/${page}`, data, { headers: { Authorization: `Bearer ${token || ''}` } });
}
export function getAllApps(id) {
    console.log("id", id)
    return axios.get(`${__env.dataApi}/adminDashboard/getRetailers/${id}`);
}
export function AppsAssigning(token, appId, id) {

    return axios.post(`${__env.dataApi}/adminDashboard/assigning/${id}?appId=${appId}`, { headers: { Authorization: `Bearer ${token || ''}` } });
}
export function fetchAppsList(token) {

    return axios.get(`${__env.dataApi}/adminDashboard/allApps`, { headers: { Authorization: `Bearer ${token || ''}` } });
}

export function GetAllCategories(token) {

    return axios.get(`${__env.dataApi}/get_categories`, { headers: { Authorization: `Bearer ${token || ''}` } });
}

export function GetAllPages(token,category) {
    console.log("category---\n",category)

    return axios.get(`${__env.dataApi}/getPages/${category}`, { headers: { Authorization: `Bearer ${token || ''}` } });
}