const __env = {};

let environment = "prod"; // can be dev, test or prod.

__env.name = environment;
// environment specific constants
if (environment === "prod") {
  __env.dataApi = "http://ec2-3-111-208-149.ap-south-1.compute.amazonaws.com:8001";
  __env.profileUploadFolder = "prescription";
} else if (environment === "test") {
  __env.dataApi = "https://testapi.whilter.com";
  __env.profileUploadFolder = "prescription";
} else {
  // DEFAULT - assume dev
  __env.dataApi =
    "http://ec2-3-108-221-157.ap-south-1.compute.amazonaws.com:8001";
  __env.profileUploadFolder = "prescription";
}

export default __env;
