import React, { useMemo, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { OrganizationsTable } from "./organizations-table/OrganizationsTable";
import { OrganizationsGrouping } from "./organizations-grouping/OrganizationsGrouping";
import { useOrganizationsUIContext } from "./OrganizationsUIContext";
import { useDispatch } from "react-redux";
import * as actions from "./_redux/organizationsActions";


export function OrganizationsCard() {
  const organizationsUIContext = useOrganizationsUIContext();
  const organizationsUIProps = useMemo(() => {
    return {
      ids: organizationsUIContext.ids,
      newOrganizationButtonClick: organizationsUIContext.newOrganizationButtonClick,
    };
  }, [organizationsUIContext]);

  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.organizations }),
  //   shallowEqual
  // );
  // const { totalCount, entities, listLoading } = currentState;
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchOrganizations());
  }, [dispatch])
  
  return (
    <Card>
      <CardHeader title="Organizations list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={organizationsUIProps.newOrganizationButtonClick}
          >
            New Organization
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <OrganizationsFilter /> */}
        {organizationsUIProps.ids.length > 0 && <OrganizationsGrouping />}
        <OrganizationsTable />
      </CardBody>
    </Card>
  );
}
