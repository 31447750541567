import rules from "../rbac-rules";

const check = (rules, roles, action, data) => {
  // const permissions = rules[roles];
  // if (!permissions) {
  //     // role is not present in the rules
  //     return false;
  // }
  let staticPermissions = [];
  let permissions = null;
  if (roles && roles.length > 0) {
    permissions = roles.map((role) =>
      rules[role] ? rules[role].static : null
    );
    staticPermissions = [].concat.apply([], permissions);
  }

  if (!staticPermissions || staticPermissions.length === 0) {
    // role is not present in the rules
    return false;
  }

  // const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = (props) =>
  check(rules, props.roles, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;

// src: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/const
