import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as authSaga from "../app/modules/Auth/_redux/authSaga";
import { usersSlice } from "../app/pages/Users/_redux/usersSlice";
import { organizationsSlice } from "../app/pages/Organizations/_redux/organizationsSlice";
import { expertsSlice } from "../app/pages/Experts/_redux/expertsSlice";
import { calendarsSlice } from "../app/pages/Bookings/_redux/calendarsSlice";
import { holidaysSlice } from "../app/pages/Holidays/_redux/holidaysSlice";
import { couponSlice } from "../app/pages/Coupon/redux/couponSlice";
import { bookingSlice } from "../app/pages/Booking/redux/bookingSlice";
import { offerSlice } from "../app/pages/Offer/redux/offerSlice";
import { applicationsSlice } from "../app/pages/Applications/_redux/applicationsSlice";
import { retailerSlice } from "../app/pages/Applications/_redux/retailerSlice";
import { appSlice } from "../app/pages/AppList/redux/appSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: usersSlice.reducer,
  organizations: organizationsSlice.reducer,
  calendar: calendarsSlice.reducer,
  experts: expertsSlice.reducer,
  holidays: holidaysSlice.reducer,
  coupons: couponSlice.reducer,
  bookings: bookingSlice.reducer,
  offers: offerSlice.reducer,
  applications: applicationsSlice.reducer,
  retailer: retailerSlice.reducer,
  apps: appSlice.reducer,
});

export function* rootSaga() {
  yield all([authSaga.saga()]);
}
