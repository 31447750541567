import axios from "axios";

export const LOGIN_URL = "auth/login";
export const REGISTER_URL = "auth/guest/register";
export const REQUEST_PASSWORD_URL = "auth/resetPassword";

export const ME_URL = "profile";

export function login(username, password) {
  console.log("Login", LOGIN_URL);
  return axios.post(LOGIN_URL, { username, password });
}

export function register(userData) {
  return axios.post(REGISTER_URL, userData);
}

export function requestPassword(mobile) {
  return axios.post(`${REQUEST_PASSWORD_URL}/${mobile}`, { mobile });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
