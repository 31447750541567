import { Grid, Modal, Box, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { BookingsTable } from "../../../../components/RetailerApplications/BookingsTable";
import classes from "./Dashboard.module.css";
import BookingChart from "../../../../components/charts/BookingChart";
import CouponsChart from "../../../../components/charts/CouponsChart";
import AssignedCouponChart from "../../../../components/charts/AssignedCouponChart";
import AvailedCouponChart from "../../../../components/charts/AvailedCouponChart";
import NotAvailedCouponChart from "../../../../components/charts/NotAvailedCouponChart";
import AssignedCoupons from "../../../../components/RetailerApplications/AssignedCoupons";
import AvailedCoupons from "../../../../components/RetailerApplications/AvailedCoupons";
import CouponsList from "../../../../components/RetailerApplications/CouponsList";
import AssignedButNotAvailedCoupons from "../../../../components/RetailerApplications/AssignedButNotAvailedCoupons";

export default function Dashboard({ appDetail }) {
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
 // const [fullscreen, setFullscreen] = useState(true);
  const [table, setTable] = useState(false);
  const [availed, setAvailed] = useState(false);
  const [notAvailed, setNotAvailed] = useState(false);
  const [coupons, setCoupons] = useState(false);

  console.log("table", table);
  const handleTable = () => setTable(true);
  const handleAvailed = () => setAvailed(true);
  const handleNotAvailed = () => setNotAvailed(true);
  const handleCoupons = (breakpoint) =>
 // setFullscreen(breakpoint);
   setCoupons(true);
  return (
    <>

      <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={table}
        onClose={() => setTable(false)}
     
      >
        <Paper>
          <div>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              Assigned CouponsList
            </div>
            <div className={classes.div_modal}
             
            >
              <AssignedCoupons />
            </div>
          </div>
        </Paper>
      </Modal>

      <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={availed}
        onClose={() => setAvailed(false)}
      >
        <Paper>
          <div>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              Availed CouponsList
            </div>
               <div className={classes.div_modal}>
              <AvailedCoupons />
            </div>
          </div>
        </Paper>
      </Modal>

      <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={notAvailed}
        onClose={() => setNotAvailed(false)}
      >
        <Paper>
          <div>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              NotAvailed CouponsList
            </div>
            <div className={classes.div_modal}>
              <AssignedButNotAvailedCoupons />
            </div>
          </div>
        </Paper>
      </Modal>


      <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={coupons}
        // fullscreen={fullscreen}
        onClose={() => setCoupons(false)}
      >
        <Paper>
       
          <div>
         
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              CouponsList
            </div>
            <div className={classes.div_modal}>
              <CouponsList />
            </div>
          </div>
           </Paper>
       
      </Modal>
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 d-inline-block dashCard">
            <div className="d-flex">
              <span className="svg-icon svg-icon-3x svg-icon-warning my-2">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <rect
                      id="Rectangle-62-Copy"
                      fill="#000000"
                      opacity="0.3"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-2"
                      fill="#000000"
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-4"
                      fill="#000000"
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-3"
                      fill="#000000"
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                    ></rect>
                  </g>
                </svg>
              </span>
              <div className="text-warning font-weight-bold font-size-h6 ml-3 mt-2 d-inline text-center w-100">
                Total Number Of Bookings
              </div>
            </div>
            <div className="text-warning font-weight-bold font-size-h2 mt-2 text-center">
              {appDetail.appointments ? appDetail.appointments : <h4>0</h4>}
            </div>
          </div>
          <div className="mt-2">
            <BookingChart />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7 d-inline-block dashCard">
            <div className="d-flex">
              <span className="svg-icon svg-icon-3x svg-icon-danger my-2">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <rect
                      id="Rectangle-62-Copy"
                      fill="#000000"
                      opacity="0.3"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-2"
                      fill="#000000"
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-4"
                      fill="#000000"
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-3"
                      fill="#000000"
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                    ></rect>
                  </g>
                </svg>
              </span>
              <div className="text-danger font-weight-bold font-size-h6 ml-3 mt-2 d-inline text-center w-100">
                Pending Bookings
              </div>
            </div>
            <div className="text-danger font-weight-bold font-size-h2 mt-2 text-center">
              {appDetail.notVisited ? appDetail.notVisited : <h4>0</h4>}
            </div>
          </div>
          <div className="mt-2">
            <EmployeeAreaChart />
          </div>
        </Grid>
  */}
        <Grid item xs={12} md={6} sm={12}  style={{ borderTop: "1px solid grey" }}>
      
          <div onClick={handleCoupons}
            className="col bg-light-primary px-6 py-8 rounded-xl mr-7 d-inline-block dashCard">
            <div className="d-flex">
              <span className="svg-icon svg-icon-3x svg-icon-primary my-2">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <rect
                      id="Rectangle-62-Copy"
                      fill="#000000"
                      opacity="0.3"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-2"
                      fill="#000000"
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-4"
                      fill="#000000"
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-3"
                      fill="#000000"
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                    ></rect>
                  </g>
                </svg>
              </span>
              <div className="text-primary font-weight-bold font-size-h6 ml-3 mt-2 d-inline text-center w-100">
                Total Number Of Coupons
              </div>
            </div>
            <div className="text-primary font-weight-bold font-size-h2 mt-2 text-center">
              {appDetail.couponCodes ? appDetail.couponCodes : <h4>0</h4>}
            </div>
          </div>
          <div className="mt-2">
            <CouponsChart />
          </div>
        
        </Grid>
        <Grid item xs={12} md={6} sm={12} style={{ borderTop: "1px solid grey" }}>

          <div
            onClick={handleTable}
            className="col bg-light-warning px-6 py-8 rounded-xl mr-7 d-inline-block dashCard"
          >
            <div className="d-flex">
              <span className="svg-icon svg-icon-3x svg-icon-warning my-2">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <rect
                      id="Rectangle-62-Copy"
                      fill="#000000"
                      opacity="0.3"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-2"
                      fill="#000000"
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-4"
                      fill="#000000"
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-3"
                      fill="#000000"
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                    ></rect>
                  </g>
                </svg>
              </span>
              <div className="text-primary font-weight-bold font-size-h6 ml-3 mt-2 d-inline text-center w-100">
                Coupons Assigned
              </div>
            </div>
            <div className="text-primary font-weight-bold font-size-h2 mt-2 text-center">
              {appDetail.assignCoupons ? appDetail.assignCoupons : <h4>0</h4>}
            </div>
          </div>
          <div className="mt-2">
            <AssignedCouponChart />
          </div>


        </Grid>
        <Grid item xs={12}  md={6}  sm={12} style={{ borderTop: "1px solid grey" }}>

          <div onClick={handleAvailed}
            className="col bg-light-info px-6 py-8 rounded-xl mr-7 d-inline-block dashCard">
            <div className="d-flex">
              <span className="svg-icon svg-icon-3x svg-icon-danger my-2">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <rect
                      id="Rectangle-62-Copy"
                      fill="#000000"
                      opacity="0.3"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-2"
                      fill="#000000"
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-4"
                      fill="#000000"
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-3"
                      fill="#000000"
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                    ></rect>
                  </g>
                </svg>
              </span>
              <div className="text-primary font-weight-bold font-size-h6 ml-3 mt-2 d-inline text-center w-100">
                Coupons Availed
              </div>
            </div>
            <div className="text-primary font-weight-bold font-size-h2 mt-2 text-center">
              {appDetail.availCoupons ? appDetail.availCoupons : <h4>0</h4>}
            </div>
          </div>
          <div className="mt-2">
            <AvailedCouponChart />
          </div>
        </Grid>
        <Grid item xs={12}  md={6} sm={12} style={{ borderTop: "1px solid grey" }}>
          <div onClick={handleNotAvailed}
            className="col bg-light-primary px-6 py-8 rounded-xl mr-7 d-inline-block dashCard">
            <div className="d-flex">
              <span className="svg-icon svg-icon-3x svg-icon-primary my-2">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <rect
                      id="Rectangle-62-Copy"
                      fill="#000000"
                      opacity="0.3"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-2"
                      fill="#000000"
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-4"
                      fill="#000000"
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                    ></rect>
                    <rect
                      id="Rectangle-62-Copy-3"
                      fill="#000000"
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                    ></rect>
                  </g>
                </svg>
              </span>
              <div className="text-primary font-weight-bold font-size-h6 ml-3 mt-2 d-inline text-center w-100">
                Coupons Assigned But Not Availed
              </div>
            </div>
            <div className="text-primary font-weight-bold font-size-h2 mt-2 text-center">
              {appDetail.notAvailed ? (
                appDetail.notAvailed
              ) : (
                <h4>0</h4>
              )}
            </div>
          </div>
          <div className="mt-2">
            <NotAvailedCouponChart />
          </div>
        </Grid>
      </Grid>

    </>
  );
}
