import * as requestFromServer from "./couponCrud";
import { couponSlice } from "./couponSlice";
import { getErrorMessage, getErrorMessageDetails } from "../../../utils";

const { actions } = couponSlice;
export const Coupon = (formData, appId, couponCode) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .CreateCoupon(formData, appId)
    .then((response) => {
      const couponResponse = response.data;
      dispatch(actions.couponData({ couponResponse }));
      console.log("action", couponResponse);
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
      console.log("error", error);
    });
};

export const fetchCoupon = () => (dispatch) => {
  console.log("FETCHING...");
  dispatch(actions.startCall());
  return requestFromServer
    .getCoupon()
    .then((response) => {
      const couponResponse = response.data;
      dispatch(actions.fetchCouponData({ couponResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const fetchAllCoupons = (token) => (dispatch) => {
  console.log("FETCHING...");
  dispatch(actions.startCall());
  return requestFromServer
    .getAllCoupons(token)
    .then((response) => {
      const couponResponse = response.data;
      dispatch(actions.totalCoupons({ couponResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const fetchAppData = () => (dispatch) => {
  console.log("FETCHING...");
  dispatch(actions.startCall());
  return requestFromServer
    .getAppDetails()
    .then((response) => {
      const couponResponse = response.data;
      console.log("appResponse", couponResponse);
      dispatch(actions.fetchAppDetails({ couponResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const ScanCoupon = (token, scannedData) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .AvailCoupon(token, scannedData)
    .then((response) => {
      console.log(response);
      const couponResponse = response.data;
      dispatch(actions.CouponAvailed({ couponResponse }));
      console.log("action", couponResponse);
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      const errorDetails = getErrorMessageDetails(error);

      dispatch(actions.catchError({ error: errorMessage }));
      console.log("ERROR ", error);
      console.log("ERROR MESSAGE", errorMessage);
      console.log("ERROR Details", errorDetails);
      throw error;
    });
};
