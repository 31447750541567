import React, { useMemo, useEffect, useState, useRef } from "react";
import moment from "moment";
import {
  Card,
  CardBody, Input,

} from "../../../../_metronic/_partials/controls";
import {
  Box,
  Grid,
  Button,
  Typography
} from "@material-ui/core";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import DatePicker from 'react-datepicker';

/* ========= Related to the form ========= */
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { App, FetchAllCategories, FetchAllPages } from '../../../pages/AppList/redux/appAction';
import { useHistory } from 'react-router-dom';
/* ========= Material UI icons ========= */


/* ========= react-redux ========= */
import { useDispatch } from "react-redux";
//import * as actions from "./_redux/organizationsActions";

/* ========= style for form ========= */
import "../../../../styles/createAppForm.scss"

const CreateAppForm = ({ auth, ...props }) => {
  const history = useHistory();
  const [date, setDate] = useState(null)
  const [openTime, setOpenTime] = useState(new Date());
  const [closeTime, setCloseTime] = useState(new Date());
  const [category, setCategory] = useState("Choose Category");
  const [landingPage, setLandingPage] = useState("Choose Template");

  useEffect(() => {
    if (auth) {
      props.FetchAllCategories(auth.authToken)

    }
  }, [auth])

  const handleSelect = (eventKey) => {
    setCategory(eventKey)
    props.FetchAllPages(auth.authToken, eventKey)

  }

  const handleSelectedPage = (e) => {
    setLandingPage(e)
  }
  const opentime = moment(openTime).format('hh:mm A');
  const closetime = moment(closeTime).format('hh:mm A');
  const handleApp = (values) => {
    const formData = {
      ...values,
      openTime: opentime,
      closeTime: closetime,


    }
    if (formData) {
      console.log("formData", typeof openTime)
      props.App(auth.authToken, category, landingPage, formData).then(response => {
        toast.success("App created successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 })
        history.push("/apps")
      }).catch(err => {
        toast.error('Something went wrong', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 })
      })
    }
  }


  return (
    <Box p={2}>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={Yup.object().shape({
          appName: Yup.string().required("App Name is required."),
          tagLine: Yup.string().required("Tag Line is required."),
          description: Yup.string().required("Description is required."),

          //   openTime: Yup.string().required("OpenTime is required."),
          //  closeTime: Yup.string().required("Close Time is required.")

        })}
        onSubmit={handleApp}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <Box>
                  <Field
                    name="appName"
                    component={Input}
                    placeholder="AppName"
                    label="AppName"
                    onChange={handleChange}
                    value={values.appName}
                    withFeedbackLabel={false}
                    required

                  />
                </Box>

                <Box mt={2}>
                  <Field
                    name="tagLine"
                    component={Input}
                    placeholder="Tagline"
                    label="Tagline"
                    onChange={handleChange}
                    value={values.tagLine}
                    withFeedbackLabel={false}
                    required

                  />
                </Box>
                <Box mt={2}>
                  <Field
                    name="description"
                    component={Input}
                    placeholder="Description"
                    label="Description"
                    onChange={handleChange}
                    value={values.description}
                    withFeedbackLabel={false}
                    required

                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>

                <Box>
                  <Typography>Open Time</Typography>
                  <DatePicker
                    selected={openTime}
                    className="od__textField datepicker"
                    onChange={(date) => setOpenTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </Box>
                <Box mt={1}>
                  <Typography>Close Time</Typography>
                  <DatePicker
                    selected={closeTime}
                    onChange={(date) => setCloseTime(date)}
                    className="od__textField datepicker"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </Box>

                <Box mt={2}>

                  <DropdownButton
                    fullWidth={true}
                    alignLeft
                    title={category}

                    id="dropdown-basic-button"
                    className="d-inline mt-6 mr-5 w-100"
                    variant="secondary"
                    onSelect={handleSelect}
                  >
                    {props.allCategories ? (
                      props.allCategories.map((item, index) => (
                        <Dropdown.Item eventKey={item.categoryId}>
                          {item.categoryName}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <div>Loading ...</div>
                    )}

                  </DropdownButton>

                </Box>
                <Box mt={2}>
                  {category &&
                    <DropdownButton
                      fullWidth={true}
                      alignLeft
                      title={landingPage}
                      id="dropdown-basic-button"
                      className="d-inline mt-6 mr-5"
                      variant="secondary"
                      onSelect={handleSelectedPage}
                    >
                      {props.allPages ? (
                        props.allPages.map((item, index) => (
                          <Dropdown.Item eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <div>Loading ...</div>
                      )}
                    </DropdownButton>
                  }
                </Box>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn btn-primary btn-elevate"
                  >
                    Add App
                  </Button>
                </Box>
              </Grid>

            </Grid>

          </form>
        )}
      </Formik>
    </Box>


  );
}
const mapStateToProps = (state) => ({
  error: state.calendar.error,
  success: state.calendar.success,
  loading: state.calendar.loading,
  app: state.calendar.booking,
  allCategories: state.apps.allCategories,
  allPages: state.apps.allPages,
  auth: state.auth,
})

const mapActionsToProps = {
  App,
  FetchAllCategories,
  FetchAllPages

};
export default connect(mapStateToProps, mapActionsToProps)(CreateAppForm);
