import { createSlice } from "@reduxjs/toolkit";

const initialApplicationsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  //applicationForEdit: undefined,
  lastError: null,

  retailerapplicationsLoading: false,
  retailerapplications: [],
  retailerapplicationsError: null,
  retailerDetail: {},
  retailerDetailLoading: false,
  retailerDetailError: null,

  appDetail: {},
  appDetailLoading: false,
  appBooking: [],
  appBookingLoading: false,
  appCoupon: [],
  appCouponLoading: false,
  appAssignedCoupon: [],
  assignedCouponLoading: false,
  appAvailedCoupon: [],
  availedCouponLoading: false,
  assignedButNotAvailedCoupon: [],
  assignedButNotAvailedCouponLoading: false,
  appGraphData: [],
  appGraphLoading: false,
  appCouponData: [],
  appCouponLoading: false,
  assignedCouponGraph: [],
  assignedCouponLoading: false,
  availedCouponGraph: [],
  availedCouponLoading: false,
  notAvailedCouponGraph: [],
  notAvailedCouponLoading: false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const applicationsSlice = createSlice({
  name: "applications",
  initialState: initialApplicationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getApplicationById
    applicationFetched: (state, action) => {
      state.actionsLoading = false;
      state.applicationForEdit = action.payload.applicationForEdit;
      state.error = null;
    },
    // findApplications
    applicationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createApplication
    applicationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.application);
    },
    // applicationsViewBookings
    applicationsViewBookings: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    retailerapplicationsFetched: (state, action) => {
      const { applications } = action.payload;
      state.retailerapplicationsLoading = false;
      state.retailerapplicationsError = null;
      state.retailerapplications = applications;
    },
    retailerapplicationsFetching: (state, action) => {
      state.retailerapplicationsLoading = true;
      state.retailerapplicationsError = null;
    },
    errorretailerApplications: (state, action) => {
      const { error } = action.payload;
      state.retailerapplicationsError = error;
      state.retailerapplicationsLoading = false;
    },
    retailerFetched: (state, action) => {
      const { retailer } = action.payload;
      state.retailerDetailLoading = false;
      state.retailerDetailError = null;
      state.retailerDetail = retailer;
    },
    retailerFetching: (state, action) => {
      state.retailerDetailError = null;
      state.retailerDetailLoading = true;
    },
    errorretailerFetching: (state, action) => {
      const { error } = action.payload;
      state.retailerDetailError = error;
      state.retailerDetailLoading = false;
    },

    appDetailFetched: (state, action) => {
      const { appDetail } = action.payload;
      state.appDetailLoading = false;
      state.appDetail = appDetail;
    },
    appDetailFetching: (state, action) => {
      state.appDetailLoading = true;
    },
    errorappDetail: (state, action) => {
      state.appDetailLoading = false;
    },
    appBookingFetched: (state, action) => {
      const { appBooking } = action.payload;
      state.appBookingLoading = false;
      state.appBooking = appBooking;
    },
    appBookingFetching: (state, action) => {
      state.appBookingLoading = true;
    },
    errorappBooking: (state, action) => {
      state.appBookingLoading = false;
    },
    appCouponFetched: (state, action) => {
      const { appCoupon } = action.payload;
      state.appCouponLoading = false;
      state.appCoupon = appCoupon;
    },
    appCouponFetching: (state, action) => {
      state.appCouponLoading = true;
    },
    errorappCoupon: (state, action) => {
      state.appCouponLoading = false;
    },


    assignedCouponFetched: (state, action) => {
      const { appAssignedCoupon } = action.payload;
      state.assignedCouponLoading = false;
      state.appAssignedCoupon = appAssignedCoupon;
    },
    assignedCouponFetching: (state, action) => {
      state.assignedCouponLoading = true;
    },
    errorassignedCoupon: (state, action) => {
      state.assignedCouponLoading = false;
    },

    availedCouponFetched: (state, action) => {
      const { appAvailedCoupon } = action.payload;
      state.assignedCouponLoading = false;
      state.appAvailedCoupon = appAvailedCoupon;
    },
    availedCouponFetching: (state, action) => {
      state.assignedCouponLoading = true;
    },
    erroravailedCoupon: (state, action) => {
      state.assignedCouponLoading = false;
    },

    assignedButNotAvailedCouponFetched: (state, action) => {
      const { assignedButNotAvailedCoupon } = action.payload;
      state.assignedCouponLoading = false;
      state.assignedButNotAvailedCoupon = assignedButNotAvailedCoupon;
    },
    assignedButNotAvailedCouponFetching: (state, action) => {
      state.assignedCouponLoading = true;
    },
    errorAssignedButNotAvailedCoupon: (state, action) => {
      state.assignedCouponLoading = false;
    },

    appGraphFetched: (state, action) => {
      const { appGraphData } = action.payload;
      state.appGraphLoading = false;
      state.appGraphData = appGraphData;
    },
    appGraphFetching: (state, action) => {
      state.appGraphLoading = true;
    },
    errorGraphCoupon: (state, action) => {
      state.appGraphLoading = false;
    },

    appCouponGraphFetched: (state, action) => {
      const { appCouponData } = action.payload;
      state.appGraphLoading = false;
      state.appCouponData = appCouponData;
    },
    appCouponGraphFetching: (state, action) => {
      state.appGraphLoading = true;
    },
    errorCouponGraph: (state, action) => {
      state.appGraphLoading = false;
    },


    assignedCouponGraphFetched: (state, action) => {
      const { assignedCouponGraph } = action.payload;
      state.appGraphLoading = false;
      state.assignedCouponGraph = assignedCouponGraph;
    },
    assignedCouponGraphFetching: (state, action) => {
      state.appGraphLoading = true;
    },
    errorAssignedCouponGraph: (state, action) => {
      state.appGraphLoading = false;
    },

    availedCouponGraphFetched: (state, action) => {
      const { availedCouponGraph } = action.payload;
      state.appGraphLoading = false;
      state.availedCouponGraph = availedCouponGraph;
    },
    availedCouponGraphFetching: (state, action) => {
      state.appGraphLoading = true;
    },
    errorAvailedCouponGraph: (state, action) => {
      state.appGraphLoading = false;
    },

    notAvailedCouponGraphFetched: (state, action) => {
      const { notAvailedCouponGraph } = action.payload;
      state.appGraphLoading = false;
      state.notAvailedCouponGraph = notAvailedCouponGraph;
    },
    notAvailedCouponGraphFetching: (state, action) => {
      state.appGraphLoading = true;
    },
    errorNotAvailedCouponGraph: (state, action) => {
      state.appGraphLoading = false;
    },
  }
});
