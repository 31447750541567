import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import BookingModalForm from './BookingModalForm';
import BookingModalHeader from './BookingModalHeader';
import {
    getBookingData,
    searchServiceProviderByMobile,
    createBookingOnCalendar,
    editBookingOnCalendar,
    createUserBookingOnCalendar
} from '../_redux/calendarsAction';

const initBookingModal = {
    customer: {
        dob: moment().subtract(10, 'years').toDate(),
        gender: "",
        mobile: "",
        name: "",
    },
    title: "Meeting",
    start: moment(new Date()).add(10, 'minutes').toDate(),
    end: moment(new Date()).add(1, 'hours').toDate(),
    invitees: "",
    id: null
}
class BookingModal extends Component {
    state = {
        uesrId: this.props.userId || null,
        showModal: this.props.show || false,
        meetingUrl: '',
        bookingToSet: initBookingModal
    }

    handleModal = (flag) => {
        this.setState({ showModal: flag });
        this.props.onHideClick();
    }

    handleSave = (bookingData, { setStatus, setSubmitting }) => {
        console.log(bookingData, this.state.userId);
        const { createBookingOnCalendar, editBookingOnCalendar, createUserBookingOnCalendar } = this.props;
        if (this.state.userId) {
            console.log("createUserBookingOnCalendar");
            createUserBookingOnCalendar(this.state.userId, bookingData).then(({ data }) => {
                // this.props.getBookingData();
                this.handleModal(false);
            })
                .catch((error) => {
                    setSubmitting(false);
                    setStatus("Something went wrong");
                });
        } else {
            if (bookingData.id) {
                editBookingOnCalendar(bookingData.id, bookingData).then(({ data }) => {
                    this.props.getBookingData();
                    this.handleModal(false);
                })
                    .catch((error) => {
                        setSubmitting(false);
                        setStatus("Something went wrong");
                    });

            } else {
                createBookingOnCalendar(bookingData).then(({ data }) => {
                    // this.props.getBookingData();
                    this.handleModal(false);
                })
                    .catch((error) => {
                        setSubmitting(false);
                        setStatus("Something went wrong");
                    });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({ showModal: this.props.show })
        }
        if (prevProps.userId !== this.props.userId) {
            this.setState({ userId: this.props.userId })
        }
        if (prevProps.serviceProviders !== this.props.serviceProviders) {
            if (!this.props.serviceProviders || this.props.serviceProviders.length === 0) {
                this.setState({ bookingToSet: initBookingModal })
            } else {
                this.setState({
                    bookingToSet: {
                        customer: { ...this.props.serviceProviders[0] },
                        title: "Meeting",
                        start: moment(new Date()).add(10, 'minutes').toDate(),
                        end: moment(new Date()).add(1, 'hours').toDate(),
                        invitees: "",
                        id: null
                    }
                })
            }
        }

    }

    render() {
        const { bookingToSet, showModal } = this.state;
        const { calendarBookings, serviceProviders, searchServiceProviderByMobile, loading } = this.props;

        return (<>
            <Modal
                size="lg"
                show={showModal}
                onHide={() => this.handleModal(false)}
                aria-labelledby="booking-modal-sizes-title-lg">

                <BookingModalHeader title="Add Booking" />

                <BookingModalForm
                    actionsLoading={loading}
                    handleSave={this.handleSave}
                    bookingToSet={bookingToSet}
                    onHide={this.handleModal}
                    calendarBookings={calendarBookings}
                    serviceProviders={serviceProviders}
                    searchServiceProviderByMobile={searchServiceProviderByMobile} />
            </Modal>
        </>)
    }
}


const mapStateToProps = (state) => ({
    error: state.calendar.error,
    success: state.calendar.success,
    loading: state.calendar.loading,
    calendarBookings: state.calendar.calendarBookings,
    serviceProviders: state.calendar.serviceProviders,
})

const mapActionsToProps = {
    getBookingData,
    searchServiceProviderByMobile,
    createBookingOnCalendar,
    editBookingOnCalendar,
    createUserBookingOnCalendar
};


export default connect(mapStateToProps, mapActionsToProps)(BookingModal);