import * as requestFromServer from "./bookingCrud";
import { bookingSlice } from "./bookingSlice";
import { getErrorMessage } from "../../../utils";

const { actions } = bookingSlice;

export const Booking = (data) => (dispatch) => {
    dispatch(actions.startCall());
    return requestFromServer
        .CreateBooking(data)
        .then((response) => {
            const bookingResponse = response.data;
            dispatch(actions.bookingData({ bookingResponse }));
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            dispatch(actions.catchError({ error: errorMessage }));
        });
};

export const fetchTotalBooking = (token) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .getALLBookings(token)
    .then((response) => {
      const bookingResponse = response.data;
      console.log("totallBokinggggg", bookingResponse)
      dispatch(actions.totalBookings({ bookingResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const fetchBookingData = (appid) => (dispatch) => {
    dispatch(actions.startCall());
    return requestFromServer
      .fetchBookings(appid)
      .then((response) => {
        const bookingResponse = response.data;
        console.log("bookingResponsne4", bookingResponse)
        dispatch(actions.bookingFetched({ bookingResponse }));
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
      });
  };