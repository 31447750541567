import axios from "axios";
import __env from "../../../../env";

export function CreateCoupon(formData, appId) {
  console.log(`${__env.dataApi}/couponCode/getCode`);
  console.log(formData);
  return axios.post(`${__env.dataApi}/couponCode/getCode/${appId}`, formData);
}

export function assignCoupon(formData) {
  console.log(`${__env.dataApi}/couponCode/assignCode`);
  console.log(formData);
  return axios.post(`${__env.dataApi}/couponCode/assignCode`, formData);
}

export function getCoupon() {
  return axios.get(
    `${__env.dataApi}/couponCode/getCouponById/624e7ba140cf161c089fe995`
  );
}

export function getAllCoupons(token) {
  return axios.get(`${__env.dataApi}/adminDashboard/getCoupons`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}

export function getAppDetails() {
  return axios.get(
    `${__env.dataApi}/retailer/getRetailerByAppId/1cfcfcb9-9cf7-462e-9e6c-73e218ed17c7`
  );
}

export function AvailCoupon(token, scannedData) {
  console.log(`${__env.dataApi}/avail/scan`);
  console.log(
    "Scanned Data\n",
    scannedData.mobile + "\n" + scannedData.couponCode
  );
  const params = JSON.stringify({
    mobile: scannedData.mobile,
    couponCode: scannedData.couponCode,
  });
  console.log(params);
  return axios.post(`${__env.dataApi}/avail/scan`, params, {
    headers: {
      Authorization: `Bearer ${token || ""}`,
      "Content-Type": "application/json",
    },
  });
}
