import * as requestFromServer from "./calendarsCrud";
import { calendarsSlice } from "./calendarsSlice";
import { getErrorMessage } from "../../../utils";

const { actions } = calendarsSlice;

export const getBookingData = () => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .fetchBookings()
    .then((response) => {
      const calendarBookings = response.data;
      dispatch(actions.bookingsFetched({ calendarBookings }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const getBookingById = (bookingId) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .fetchBookingById(bookingId)
    .then((response) => {
      console.log(response.data);
      const selectedBooking = response.data;
      dispatch(actions.bookingFetched({ selectedBooking }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const searchServiceProviderByMobile = (mobile) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    if (!mobile) {
      resolve([]);
      return dispatch(
        actions.serviceProvidersFetched({ serviceProviders: [] })
      );
    }

    dispatch(actions.startCall());
    return requestFromServer
      .fetchServiceProviderByMobile(mobile)
      .then((response) => {
        const serviceProviders = response.data;
        dispatch(actions.serviceProvidersFetched({ serviceProviders }));
        resolve(serviceProviders);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

export const createBookingOnCalendar = (data) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    requestFromServer
      .createBooking(data)
      .then((response) => {
        const booking = response.data;
        dispatch(
          actions.bookingCreated({
            success: "The Meeting is Created Successfully!",
            booking,
          })
        );
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

export const editBookingOnCalendar = (bookingId, bookingData) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    requestFromServer
      .editBooking(bookingId, bookingData)
      .then((response) => {
        dispatch(
          actions.bookingUpdated({
            success: "The Meeting is Updated Successfully!",
          })
        );
        setTimeout(() => dispatch(getBookingData()));
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

export const createUserBookingOnCalendar = (userId, data) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    requestFromServer
      .createUserBooking(userId, data)
      .then((response) => {
        const booking = response.data;
        dispatch(
          actions.bookingCreated({
            success: "The Meeting is Created Successfully!",
            booking,
          })
        );
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

export const createUser = (customerData) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    requestFromServer
      .createCustomer(customerData)
      .then((response) => {
        dispatch(
          actions.catchSuccessMessage({
            success: "The User is Created Successfully!",
          })
        );
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

export const cancelBookingAction = (bookingId) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    requestFromServer
      .cancelBooking(bookingId)
      .then((response) => {
        dispatch(
          actions.catchSuccessMessage({
            success: "The Booking is Cancelled Successfully!",
          })
        );
        setTimeout(() => dispatch(getBookingData()));
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

export const saveBookingNotes = (bookingId, notes) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    requestFromServer
      .saveBookingNotes(bookingId, notes)
      .then((response) => {
        dispatch(
          actions.catchSuccessMessage({
            success: "The Notes is Updated Successfully!",
          })
        );
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};
